<section [class.factoring-section]="formType === 1">
	<header>
		<label i18n>Добавить</label>
		<app-icon (click)="close()">close</app-icon>
	</header>
	<form [formGroup]="form">
		<div>
			<label class="required" i18n>{{ isGln ? "GLN": "LLN"}} :</label>
			<div class="control-box">
				<app-numberbox formControlName="gln" maxLength="13" [isDisabled]="true"></app-numberbox>
			</div>
		</div>
		<div>
			<label i18n>Наименование:</label>
			<app-textbox formControlName="storageName" maxLength="255" name="Наименование">
			</app-textbox>
		</div>
		<div *ngIf="!formType">
			<label i18n>Код склада в своей ERP системе:</label>
			<app-textbox formControlName="storageCodeInErp" maxLength="35"
				[class.error]="form?.get('storageCodeInErp')?.errors"></app-textbox>
		</div>
		<h3 i18n>Юридический адрес склада</h3>
		<div>
			<label class="required" i18n>Почтовый индекс:</label>
			<app-textbox formControlName="postalCode" maxLength="36" [isDisabled]="isGln" name="Почтовый индекс"></app-textbox>
		</div>
		<div>
			<label class="required" i18n>Страна:</label>
			<app-auto-select-box
				storeName="customization" fieldName="countries" [mappingView]="viewList"
				[getAction]="CustomizationActions.updateCountriesFilter" [successAction]="CustomizationActions.getCountriesSuccess"
				[resetAction]="CustomizationActions.resetCountries" [valueTransformFn]="transformFn" formControlName="country"
				[showSearch]="true" class="small-height" placeholder="Не выбрано" name="Страна">
			</app-auto-select-box>
		</div>
		<div>
			<label i18n>Регион:</label>
			<app-auto-select-box
				storeName="customization" fieldName="regions" [mappingView]="viewList" [filter]="regionsFilter"
				[isDisabled]="!form?.get('country')?.value || form?.get('country')?.value.id !== 17"
				[getAction]="CustomizationActions.updateRegionsFilter" [successAction]="CustomizationActions.getRegionsSuccess"
				[resetAction]="CustomizationActions.resetRegions" [valueTransformFn]="transformFn" formControlName="region"
				[showSearch]="true" class="small-height" placeholder="Не выбрано">
			</app-auto-select-box>
		</div>
		<div>
			<label i18n>Населенный пункт:</label>
			<app-textbox formControlName="city" placeholder="Не указан" maxLength="255" [isDisabled]="isGln"></app-textbox>
		</div>
		<div>
			<label i18n>Адрес:</label>
			<div class="custom-input">
				<app-textarea class="without-border" placeholder="Не указан" formControlName="address" maxLength="255" [isDisabled]="isGln">
				</app-textarea>
			</div>
		</div>

	</form>
	<footer>
		<button type="button" [appSkin]="'action-save'" (click)="save()">Сохранить</button>
		<button [appSkin]="'simple-cancel'" (click)="close()">Закрыть</button>
	</footer>
</section>

<app-overlay #notification></app-overlay>
<app-pending *ngIf="pending$ | async"></app-pending>

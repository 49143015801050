import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class BlobHttpErrorResponseInterceptor implements HttpInterceptor {
	private fileReader: FileReader = new FileReader();
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse && error.error instanceof Blob && error.error.type === "application/json") {
					return new Promise<any>((resolve, reject) => {
						this.fileReader.onload = (event: Event): void => {
							try {
								const errorMessage = JSON.parse((event.target as FileReader)?.result?.toString() || "");
								reject(new HttpErrorResponse({ error: errorMessage }));
							} catch (err) {
								reject(error);
							}
						};
						this.fileReader.onerror = (): void => {
							reject(error);
						};
						this.fileReader.readAsText(error.error);
					});
				}
				return throwError(error);
			})
		);
	}
}

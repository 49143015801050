import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { createSelector, Store } from "@ngrx/store";
import { UserState } from "@app/user/user.reducer";
import { UserType } from "@helper/abstraction/user";
import { Injectable } from "@angular/core";
import { notNull } from "@helper/operators";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { getProfile, getUserType } from "@app/user/user.actions";

@Injectable()
export class UserTypeGuard implements CanActivate {
	constructor(
		public readonly store: Store<UserState>,
		private readonly router: Router,
	) { }
	public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const { userTypes, failRedirect } = route.data;

		this.store.dispatch(getUserType());
		this.store.dispatch(getProfile());

		if (!userTypes)
			throw new Error("Please provide array of userTypes for current route");

		const selectUserState = (appState: any): UserState => appState.user;
		const selectUserType = createSelector(selectUserState, (state: UserState): UserType | undefined => state.userType);

		return this.store.select(selectUserType).pipe(notNull(), switchMap(userType => {
			if ((userTypes as UserType[]).includes(userType))
				return of(true);
			else {
				this.router.navigateByUrl(failRedirect || "user");
				return of(false);
			}
		}));
	}
}

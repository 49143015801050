import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { OverlayService } from "@core/overlay.service";
import { DataMarkPopupComponent } from "@shared/shared-popups/data-mark-popup/data-mark-popup.component";
import { ErrorExceptionMessage, UserErrorsService } from "../services/user-errors.service";

@Injectable()
export class UserErrorInterceptor implements HttpInterceptor {
	private errorToken: ErrorExceptionMessage = {};
	constructor(
		private store: Store,
		private overlayService: OverlayService,
		private userErrorsService: UserErrorsService
	) { }

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const part = req.url.split("/");
		const urlPartIndicator = ["EWAYBILL", "downloadArchive"];
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (["EX1001", "EX1008"].includes(error.error?.errorCode) && (!part.includes(urlPartIndicator[0]) && !part.includes(urlPartIndicator[1]))) {
					this.overlayService.showNotification$(error.error?.exceptionMessage || error.error.error, "error");
					// TODO changed changeZindex
					// const changeZindex = req.params.get("okrbType") ? req.params.get("okrbType") === "UOM" : false;
          			// this.overlayService.showNotification$(error.error?.exceptionMessage || error.error.error, "error", undefined, undefined, changeZindex);
				} else if (error.error?.errorCode == "EX8008") {
					this.openDataMarkPopupComponent(error);
				}
				return throwError(error);
			})
		);
	}

	private openDataMarkPopupComponent(error: HttpErrorResponse): void {
		this.errorToken.error = error.error.error;
		this.errorToken.exceptionMessage = error.error?.exceptionMessage;
		const errorMessage = this.userErrorsService.errorExceptionMessage(this.errorToken);
		const component = this.overlayService.show(DataMarkPopupComponent, {
			inputs: {
				error: errorMessage
			},
			centerPosition: true
		});
		component.instance.close$.pipe(take(1)).subscribe(() => {
			this.overlayService?.clear();
		});
	}
}

import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs";
import { Edocument as IEdocument, EdocumentFile } from "@app/user/documents/edocument/edocument";

export class Edocument extends DocumentController<IEdocument> {
	public get apiUrl(): string { return `${this.config.api.edocument}`; }


	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	public checkSign = {
		get$: (id: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${id}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public saveFiles = {
		post$: (draftId: number, files: File[]): Observable<void> => {
			const url = `${this.apiUrl}/draft/${draftId}/saveFiles`;
			const formData: FormData = new FormData();
			files.forEach((file) => {
				formData.append("file", file, file.name);
			});
			return this.http.post<void>(url, formData, { withCredentials: true });
		}
	};

	public saveResponse = {
		post$: (draftId: number): Observable<number> => {
			const url = `${this.apiUrl}/${draftId}/response`;
			return this.http.post<number>(url, {}, { withCredentials: true });
		}
	};

	public getFiles = {
		get$: (draftId: string, type?: string): Observable<EdocumentFile[]> => {
			let url;
			if (type === "draft")
				url = `${this.apiUrl}/draft/${draftId}/files`;
			else
				url = `${this.apiUrl}/${draftId}/files`;
			return this.http.get<EdocumentFile[]>(url, { withCredentials: true });
		}
	};

	public downloadFile = {
		get$: (draftId: number, fileId: number, type?: string): Observable<HttpResponse<Blob>> => {
			let url;
			if (type === "draft")
				url = `${this.apiUrl}/draft/${draftId}/downloadFile/${fileId}`;
			else
				url = `${this.apiUrl}/${draftId}/downloadFile/${fileId}`;
			return this.http.get<Blob>(url, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public deleteFiles = {
		delete$: (draftId: number, fileIds: number[]): Observable<void> => {
			const url = `${this.apiUrl}/draft/${draftId}/deleteFiles`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: fileIds,
				withCredentials: true
			};
			return this.http.delete<void>(url, options);
		}
	};

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}

	public get$(id: number): Observable<IEdocument> {
		const url = `${this.apiUrl}/${id}`;
		return this.http.get<IEdocument>(url, { withCredentials: true });
	}
}

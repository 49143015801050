import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

export interface RegistrationRequest {
	email: string;
	unp: string;
}

export interface RegistrationDTO {
	id: string;
	name: string;
	addressExist?: boolean;
	utmParams?: any;
}

export interface RegistrationConfirmDTO {
	id?: string;
	gln: string;
	address?: string;
	promoCode?: string;
	okedId: number;
}

export interface ConfirmationBody {
	phoneNumber?: string;
	gln?: string;
	address?: string;
}

export class Registration {
	public get apiUrl(): string { return `${this.config.api.root}`; }

	public create = {
		post$: (body: RegistrationRequest): Observable<RegistrationDTO> => {
			const url = `${this.apiUrl}/registrationRequest/create`;
			return this.http.post<RegistrationDTO>(url, body);
		}
	};

	public confirm = {
		put$: (id: string, phoneNumber: string, address?: string): Observable<void> => {
			const url = `${this.apiUrl}/registrationRequest/confirm/${id}`;
			const body: ConfirmationBody = { phoneNumber };
			if (address) body.address = address;
			return this.http.put<void>(url, body);
		},
		putCTT$: (payload: RegistrationConfirmDTO): Observable<void> => {
			const url = `${this.apiUrl}/registrationRequest/confirm/${payload.id}`;
			const body = { ...payload };
			delete body.id;
			if (!payload.address) {
				delete body.address;
			}
			if (!payload.promoCode) {
				delete body.promoCode;
			}
			return this.http.put<void>(url, body);
		}
	};

	public checkApproveCode = {
		post$: (registrationRequestId: string, approveCode: string): Observable<void> => {
			let params = new HttpParams();
			params = params.set("approveCode", approveCode.toString());
			const url = `${this.apiUrl}/registrationRequest/checkApproveCode/${registrationRequestId}`;
			return this.http.get<void>(url, { params });
		}
	};

	public createPassword = {
		post$: (registrationRequestId: string, password: string): Observable<void> => {
			const url = `${this.apiUrl}/registrationRequest/finalize/${registrationRequestId}`;
			return this.http.post<void>(url, { password });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

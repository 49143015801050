import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { OverlayService } from "@core/overlay.service";
import { TranslationService } from "@core/translation.service";
import { DocumentState, processingStatusValue } from "@helper/abstraction/documents";
import { OpenDocumentFile } from "@helper/abstraction/notifications";
import { createSelector, select, Store } from "@ngrx/store";
import { Organization } from "@helper/abstraction/organization";
import { take } from "rxjs/operators";
import { documentState } from "@helper/paths";
import { UserState } from "@app/user/user.reducer";

@Injectable()
export class UserOpenDocumentFileService {

	constructor(
		private router: Router,
		private store: Store,
		private overlayService: OverlayService,
		private translationService: TranslationService,
	) { }

	public navigateByDocumentType(docType: any, document: OpenDocumentFile): void {
		if (["SENT", "WAITING_PKSPT_DRAFT"].includes(document.processingStatus)) {
			this.overlayService.showNotification$(this.translationService.getTranslation("sentDocumentMessage"), "warning");
			return;
		}
		switch (docType) {
			case "ORDERS": this.navigateOrders(document); break;
			case "DESADV": this.navigateDesadv(document); break;
			case "EWAYBILL": this.navigateEwaybill(document); break;
			case "TRANSIT": this.navigateTransit(document); break;
			case "EINVOICE": this.navigateEinvoice(document); break;
			case "EDOCUMENT": this.navigateEdocument(document); break;
			case "EINVOICEPMT": this.navigateEinvoicepmt(document); break;
		}
	}

	private navigateOrders(document: OpenDocumentFile): void {
		const queryParams = {
			draftId: document.id,
			draftType: document.msgType,
			kind: "220"
		};
		if (!document.processingStatus) {
			throw "no processing status to navigation";
		}
		const docState: DocumentState | undefined = this.getDocumentState(document);
		if (document.processingStatus === "DRAFT") {
			if (document.msgType === "ORDERS")
				this.router.navigate(["user", "documents", "ORDERS", "edit"], { queryParams });
			if (document.msgType === "ORDRSP")
				this.router.navigate(["user", "documents", "ORDERS", documentState.draft, "response", document.msgType, "220", document.id]);
		} else {
			this.router.navigate(["user", "documents", "ORDERS", docState?.toLowerCase() || document.msgType, document.id]);
		}
	}

	private navigateDesadv(document: OpenDocumentFile): void {
		const id: string = document.id.toString();
		const docState: DocumentState | undefined = this.getDocumentState(document);

		if (document.processingStatus && document.processingStatus === "DRAFT") {
			this.router.navigate(["user", "documents", "DESADV", "edit", id]);
		} else {
			this.router.navigate(["user", "documents", "DESADV", docState?.toLowerCase(), document.id]);
		}
	}

	private navigateEwaybill(document: OpenDocumentFile): void {
		const docState: DocumentState | undefined = this.getDocumentState(document);
		const navigation = this.getEwaybillNavigationList(document, docState);
		this.router.navigate(navigation.commands, navigation.extras);
	}

	private getEwaybillNavigationList(document: OpenDocumentFile, docState: any): { commands: any[]; extras?: NavigationExtras | undefined } {
		let commands: any[] = [];
		let extras = undefined;
		if (docState === "DRAFT") {
			if (document.processingStatus === "DRAFT") {
				if (document.msgType && ["BLRDNR", "BLRWBR"].includes(document.msgType)) {
					commands = ["user", "documents", "EWAYBILL", "draft", "response", document.msgType, document.id];
				}
				else if (document.msgType && ["BLRWBL", "BLRDLN"].includes(document.msgType)) {
					commands = ["user", "documents", "EWAYBILL", "edit"];
					extras = { queryParams: { draftId: document.id, draftType: document.msgType } };
				}
			}
			else if (document.processingStatus === "FINALLY_SIGNED_DRAFT") {
				if (document.msgType && ["BLRDNR", "BLRWBR"].includes(document.msgType)) {
					commands = ["user", "documents", "EWAYBILL", "response", "sign-draft", document.msgType, document.id];
				}
				else if (document.msgType && ["BLRWBL", "BLRDLN"].includes(document.msgType)) {
					commands = ["user", "documents", "EWAYBILL", "sign-draft", document.msgType, document.id];
				}
			}

		}
		else {
			commands = ["user", "documents", "EWAYBILL", docState === "INCOMING" ? "incoming" : "outgoing", document.msgType, document.id];
		}
		return { commands, extras };
	}

	private navigateTransit(document: OpenDocumentFile,): void {
		const docState: DocumentState | undefined = this.getDocumentState(document);
		const navigation = this.getTransitNavigationList(document, docState);
		this.router.navigate(navigation.commands, navigation.extras);
	}

	private getTransitNavigationList(document: OpenDocumentFile, docState: any): { commands: any[]; extras?: NavigationExtras | undefined } {
		let commands: any[] = [];
		let extras = undefined;
		if (docState === "DRAFT") {
			if (document.processingStatus === "DRAFT") {
				commands = ["user", "documents", "TRANSIT", "edit"];
				extras = { queryParams: { draftId: document.id, draftType: "TRANSIT" } };
			}
			else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(document.processingStatus)) {
				commands = ["user", "documents", "TRANSIT", "sign-draft", document.id];
			}
		}
		else {
			commands = ["user", "documents", "TRANSIT", docState === "INCOMING" ? "incoming" : "outgoing", document.id];
		}
		return { commands, extras };
	}

	private navigateEinvoice(document: OpenDocumentFile,): void {
		const docState: DocumentState | undefined = this.getDocumentState(document);
		const navigation = this.getEinvoiceNavigationList(document, docState);
		this.router.navigate(navigation.commands, navigation.extras);
	}

	private getEinvoiceNavigationList(document: OpenDocumentFile, docState: any): { commands: any[]; extras?: NavigationExtras | undefined } {
		let commands: any[] = [];
		let extras = undefined;
		if (docState === "DRAFT") {
			if (document.processingStatus === "DRAFT") {
				commands = ["user", "documents", "EINVOICE", "edit"];
				extras = { queryParams: { draftId: document.id, draftType: "BLRINV" } };
			}
			else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(document.processingStatus)) {
				commands = ["user", "documents", "EINVOICE", "sign-draft", document.id];
			}
		}
		else {
			commands = ["user", "documents", "EINVOICE", docState === "INCOMING" ? "incoming" : "outgoing", document.id];
		}
		return { commands, extras };
	}

	private navigateEdocument(document: OpenDocumentFile,): void {
		const docState: DocumentState | undefined = this.getDocumentState(document);
		const navigation = this.getEdocumentNavigationList(document, docState);
		this.router.navigate(navigation.commands, navigation.extras);
	}

	private getEdocumentNavigationList(document: OpenDocumentFile, docState: any): { commands: any[]; extras?: NavigationExtras | undefined } {
		let commands: any[] = [];
		let extras = undefined;
		if (docState === "DRAFT") {
			if (document.processingStatus === "DRAFT") {
				commands = ["user", "documents", "EDOCUMENT", "edit"];
				extras = { queryParams: { draftId: document.id, draftType: document.msgType || "BLRDOC" } };
			}
			else if (["SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT"].includes(document.processingStatus)) {
				commands = ["user", "documents", "EDOCUMENT", "sign-draft", document.id];
			}
		}
		else {
			commands = ["user", "documents", "EDOCUMENT", docState === "INCOMING" ? "incoming" : "outgoing", document.id];
		}
		return { commands, extras };
	}

	private navigateEinvoicepmt(document: OpenDocumentFile,): void {
		const docState: DocumentState | undefined = this.getDocumentState(document);
		const navigation = this.getEinvoicepmtNavigationList(document, docState);
		this.router.navigate(navigation.commands);
	}

	private getEinvoicepmtNavigationList(document: OpenDocumentFile, docState: any): { commands: any[] } {
		let commands: any[] = [];
		if (docState === "DRAFT") {
			switch (document.processingStatus) {
				case "DRAFT": {
					commands = ["user", "documents", "EINVOICEPMT", "edit", document.id];
					break;
				}
				case "SIGNED_DRAFT":
				case "FINALLY_SIGNED_DRAFT": {
					commands = ["user", "documents", "EINVOICEPMT", "signed-draft", document.id];
					break;
				}
			}
		}
		else {
			commands = ["user", "documents", "EINVOICEPMT", docState === "INCOMING" ? "incoming" : "outgoing", document.id];
		}
		return { commands };
	}


	private getDocumentState(document: OpenDocumentFile): DocumentState | undefined {
		let docState: DocumentState | undefined = undefined;
		const section = (appState: any): UserState => appState.user;
		const organizationInfo = createSelector(section, (state: UserState): Organization | undefined => state?.organizationInfo);
		this.store.pipe(select(organizationInfo), take(1)).subscribe(organization => {
			if (document.processingStatus && ["DRAFT", "SIGNED_DRAFT", "FINALLY_SIGNED_DRAFT", "SENT"].includes((document.processingStatus as any).id || document.processingStatus)) {
				docState = "DRAFT";
			}
			if (document.senderId === organization?.id && document.processingStatus && processingStatusValue.includes((document.processingStatus as any).id ||document.processingStatus)) {
				docState = "OUTGOING";
				if (document.processingStatus === "CREATED_CONFIRMED") {
					docState = "INCOMING";
				}
			}
			if (document.receiverId === organization?.id && document.processingStatus && processingStatusValue.includes((document.processingStatus as any).id ||document.processingStatus)) {
				docState = "INCOMING";
				if (document.processingStatus === "CREATED_CONFIRMED") {
					docState = "OUTGOING";
				}
			}
		});
		return docState;
	}
}

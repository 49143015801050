import { CommonInfo } from "@helper/abstraction/mns-request";
import { MnsEwaybillFilter, MnsEwaybill } from "@helper/abstraction/mns-ewaybill";
import { createAction } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { DocumentType } from "@helper/abstraction/documents";

export const updateEinvoicesFilter = createAction(
	"[MnsEinvoices] Update Statistic Filter",
	(payload: MnsEwaybillFilter): { filterParams: MnsEwaybillFilter } => ({ filterParams: payload })
);

export const resetEinvoices = createAction(
	"[MnsEinvoices] Reset Einvoices",
	(): { documents: undefined } => ({ documents: undefined })
);

export const getMnsEinvoices = createAction(
	"[MnsEinvoices] Get Mns Einvoices",
	(payload: MnsEwaybillFilter): { statisticParams: MnsEwaybillFilter } => ({ statisticParams: payload })
);

export const getMnsEinvoicesSuccess = createAction(
	"[MnsEinvoices] Get Mns Einvoices Success",
	(payload: MnsEwaybill[]): { documents: MnsEwaybill[] } => ({ documents: payload })
);

export const getMnsEinvoicesError = createAction(
	"[MnsEinvoices] Get Mns Einvoices Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getMnsEinvoicesNextPage = createAction(
	"[MnsEinvoices] Get Mns Einvoices Next Page",
	(payload: MnsEwaybillFilter): { statisticParams: MnsEwaybillFilter } => ({ statisticParams: payload })
);

export const getMnsEinvoicesNextPageSuccess = createAction(
	"[MnsEinvoices] Get Mns Einvoices Next Page Success",
	(payload: MnsEwaybill[]): { documents: MnsEwaybill[] } => ({ documents: payload })
);

export const getMnsEinvoicesNextPageError = createAction(
	"[MnsEinvoices] Get Mns Einvoices Next Page Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);
export const updateStatusesFilter = createAction(
	"[Documents] Next Mns Statuses Filter",
	(payload: "EWAYBILL"): { filter: "EWAYBILL" } => ({ filter: payload })
);

export const resetStatuses = createAction(
	"[Documents] Reset Statuses",
	(): { statuses: undefined } => ({ statuses: undefined })
);

export const getStatuses = createAction(
	"[MnsEinvoices] Get Mns Statuses",
	(payload: "EWAYBILL"): { statusesParams: "EWAYBILL" } => ({ statusesParams: payload })
);

export const getStatusesSuccess = createAction(
	"[MnsEinvoices] Get Mns Statuses Success",
	(payload: CommonInfo[]): { statuses: CommonInfo[] } => ({ statuses: payload })
);

export const getStatusesError = createAction(
	"[MnsEinvoices] Get Mns Statuses Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const setLastOpenedEinvoicesId = createAction(
	"[MnsEinvoices] Set Last Opened Einvoices Id",
	(payload: number): { lastOpenedEinvoicesId: number } => ({ lastOpenedEinvoicesId: payload })
);

export const exportDts = createAction(
	"[MnsEinvoices] Export Dts",
	(payload: { documentType: DocumentType; documentId: number; signatureId: number }): { params: { documentType: DocumentType; documentId: number; signatureId: number } } => ({ params: payload })
);

export const exportDtsSuccess = createAction(
	"[MnsEinvoices] Export Dts Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportDtsError = createAction(
	"[MnsEinvoices] Export Dts Error",
	(payload: HttpErrorResponse | Error, isMassAction = false): { error: HttpErrorResponse | Error, isMassAction: boolean } => ({ error: payload, isMassAction })
);

import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import * as DocumentsActions  from "../../../user/documents/documents-store/documents.actions";

@Component({
	selector: "app-sccrypto-version-popup",
	templateUrl: "./sccrypto-version-popup.component.html",
	styleUrls: ["./sccrypto-version-popup.component.scss"]
})
export class SccryptoVersionPopupComponent implements OnInit {
	public actualVersion = "";
	public isDocument = false;

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private readonly store: Store<any>
	) { }

	public ngOnInit(): void {
		this.setData();
	}

	public close(isClosed: boolean): void {
		this.ref.close(isClosed);
	}

	public goLink(link = "version"): void{
		switch(link){
			case "version": this.store.dispatch(DocumentsActions.downloadSCCrypto()); break;
			case "help": break;
		}
	}
	
	private setData(): void {
		this.config.width = "800px";
		this.config.header = "Контроль версии SC-CryptoProxy";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
	}
}

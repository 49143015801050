import { Component, ChangeDetectionStrategy, OnInit, ViewChild, Input } from "@angular/core";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { createSelector, select, Store } from "@ngrx/store";
import { CountriesParams } from "@helper/abstraction/countries";
import { saveStorage, otherStorageError } from "../../../customization-store/customization.actions";
import * as CustomizationActions from "../../../customization-store/customization.actions";
import { RegionsParams } from "@helper/abstraction/regions";
import { notNull } from "@helper/operators";
import { take, takeUntil } from "rxjs/operators";
import { StreetsParams } from "@helper/abstraction/streets";
import { ValidatorsUtil } from "@helper/validators-util";
import { OverlayComponent } from "@shared/overlay/overlay.component";
import { CustomizationSelectorService } from "../../../customization-selector.service";
import { StorageCreate, StorageCreateDto } from "@helper/abstraction/storages";
import { HttpErrorResponse } from "@angular/common/http";
import { FindInfoForCreatingCode } from "../storage-store/storage";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { Actions, ofType } from "@ngrx/effects";
import { UserState } from "@app/user/user.reducer";

@Component({
	selector: "app-storage-create-popup",
	templateUrl: "./storage-create-popup.component.html",
	styleUrls: ["./storage-create-popup.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageCreatePopupComponent extends DefaultPopupComponent implements OnInit {
	@Input() public formValue?: FindInfoForCreatingCode | StorageCreateDto;
	@Input() public isGln = true;
	@Input() public formType?: number;
	@Input() public receiverLln?: string;
	public form?: FormGroup;
	public pending$: Observable<boolean | undefined>;
	public messages?: Map<string, string>;
	public errorMessages?: Record<string, string[]>;
	public storageError$: Observable<HttpErrorResponse | undefined>;
	public otherStorageError$: Observable<Error | undefined>;
	public isChangeStorage$: Observable<boolean | undefined>;
	public CustomizationActions = CustomizationActions;
	public streetsFilter: StreetsParams = {
		page: 1,
		size: 20
	};
	public countriesFilter: CountriesParams = {
		page: 1,
		size: 20
	};
	public regionsFilter: RegionsParams = {
		page: 1,
		size: 20
	};
	@ViewChild("notification", { static: false }) private overlayComponent?: OverlayComponent;
	private unsubscribe$$ = new Subject<void>();

	constructor(
		private readonly store: Store,
		private formBuilder: FormBuilder,
		private userErrorsService: UserErrorsService,
		private actions: Actions,
		private readonly customizationSelectorService: CustomizationSelectorService,
	) {
		super();

		const user = (appState: any): UserState => appState.user;
		const getPending = createSelector(user, (state: UserState): boolean | undefined => state.popupPending);
		this.pending$ = this.store.pipe(select(getPending));

		this.storageError$ = this.customizationSelectorService.select$<HttpErrorResponse | undefined>(state => state.storageErrors);
		this.otherStorageError$ = this.customizationSelectorService.select$<Error | undefined>(state => state.otherStorageError);
		this.isChangeStorage$ = this.customizationSelectorService.select$<boolean | undefined>(state => state.isChangeStorage);
	}

	public viewList = (obj: any[]): [any, string][] => obj ? obj.map((p): [any, string] => ([p, p.name])) : [];

	public ngOnInit(): void {
		this.initForm();

		this.storageError$.pipe(
			notNull(),
			takeUntil(this.unsubscribe$$)
		).subscribe(messages => {
			this.overlayComponent && this.overlayComponent.showNotification$(messages && messages.error, "error");
		});

		this.otherStorageError$.pipe(
			notNull(),
			takeUntil(this.unsubscribe$$)
		).subscribe(error => {
			if (!this.messages || this.messages && !this.messages.get("serverErr"))
				throw new Error("No messages errors!");

			this.overlayComponent && this.overlayComponent.showNotification$(error.message || this.messages.get("serverErr") || "", "error");
		});

		this.isChangeStorage$.pipe(
			takeUntil(this.unsubscribe$$)
		).subscribe(persistent => {
			if (persistent)
				this.close();
		});
	}

	public initForm(): void {
		this.form = this.formBuilder.group({
			gln: [this.formValue?.gln || null],
			storageName: [this.formValue?.storageName || null, [Validators.maxLength(255)]],
			storageCodeInErp: [this.formValue?.storageCodeInErp || null, Validators.maxLength(35)],
			postalCode: [this.formValue?.postalCode || null, [Validators.required, Validators.maxLength(35)]],
			country: [this.formValue?.nsiCountryDto || { id: 17, name: "Беларусь"}, Validators.required],
			address: [this.formValue?.address || null, Validators.maxLength(255)],
			city: [this.formValue?.city || null, Validators.maxLength(255)],
			id: [this.formValue?.id || null],
			region: [this.formValue?.nsiRegionDto && Object.keys(this.formValue?.nsiRegionDto).length !== 0 ? this.formValue?.nsiRegionDto: null],
		});
	}

	public save(): void {
		if (!this.form){
			throw Error("No form");
		}
		const controlsNameList = ["postalCode", "storageName"];
		controlsNameList.forEach(name => {
			const control = this.form?.get(name);
			control?.setValue(control?.value ? control?.value.trim() : null);
		});

		ValidatorsUtil.triggerValidation(this.form);
		if (this.form.invalid) {
			this.userErrorsService.displayErrors(this.form, this.overlayComponent);
			return;
		}

		const storage: StorageCreate = {
			address: this.form.value.address,
			city: this.form.value.city,
			nsiCountryDto: { id: this.form.value.country && this.form.value.country.id },
			nsiRegionDto: { id: this.form.value.region && this.form.value.region.id },
			gln: this.form.value.gln,
			id: this.form.value.id,
			postalCode: this.form.value.postalCode,
			storageCodeInErp: this.form.value.storageCodeInErp,
			storageName: this.form.value.storageName,
			isFromEpass: false
		};

		this.formType !== 1
			? this.store.dispatch(saveStorage(storage))
			: this.addStorageForReceiver(storage);
		
		this.actions.pipe(
			ofType(otherStorageError),
			take(1)
		).subscribe(error => {
			if(error.error.error.errorCode !== "EX1001"){
				this.overlayComponent?.showNotification$(error.error.error.error, "error");
			}
		});

		this.actions.pipe(
			ofType(CustomizationActions.addStorageForReceiverError),
			takeUntil(this.unsubscribe$$)
		).subscribe(error => this.overlayComponent?.showNotification$(error?.error?.error?.error, "error"));
	}

	public getFirstErrorKey(): string | null | undefined {
		const glnFormGroup = this.form && this.form.get("gln");
		if (glnFormGroup) {
			const formErrorKey = glnFormGroup.errors && Object.keys(glnFormGroup.errors)[0];
			return formErrorKey || (this.errorMessages && this.errorMessages.gln.length ? "back" : undefined);
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	public transformFn(value: any): string {
		return value.name;
	}

	private addStorageForReceiver(storage: StorageCreate): void {
		const addStorageDto: StorageCreate = { ...storage, storageLln: storage.gln, receiverLln: this.receiverLln };

		for (let k in addStorageDto) {
			if (["gln", "storageCodeInErp"].includes(k)) {
				delete (addStorageDto as any)[k];
			}
		}
		this.store.dispatch(CustomizationActions.addStorageForReceiver(addStorageDto));
	}
}

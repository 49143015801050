import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

@Injectable()
export class AdminGuard implements CanActivate {

	constructor(private router: Router) { }

	public canActivate(): boolean {
		this.router.navigate(["user/login"]);
		return false;
	}
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { PushNotification } from "@helper/abstraction/notifications";
import { Observable } from "rxjs";

export class NotificationController {
	public notification = {
		get$: (paging: { page: number; size: number, notificationType?: string }): Observable<PushNotification> => {
			let params = new HttpParams();
			params = params.set("page", paging?.page.toString());
			params = params.set("size", paging?.size.toString());
			if (paging.notificationType) {
				params = params.set("type", paging?.notificationType.toString());
			}
			const url = `${this.config.api.root}/notification`;
			return this.http.get<PushNotification>(url, { params, withCredentials: true });
		}
	};

	public read = {
		post$: (id: number[]): Observable<void> => {
			const url = `${this.config.api.root}/notification/read`;
			return this.http.post<void>(url, id, { withCredentials: true });
		}
	};

	public readAll = {
		put$: (): Observable<void> => {
			const url = `${this.config.api.root}/notification/readAll`;
			return this.http.put<void>(url, { withCredentials: true });
		}
	};

	public unread = {
		get$: (): Observable<{ total: number }> => {
			const url = `${this.config.api.root}/notification/unread`;
			return this.http.get<{ total: number }>(url, { withCredentials: true });
		}
	};

	public action = {
		get$: (id: number, urlPath = "notification/action"): Observable<void> => {
			const url = `${this.config.api.root}/${urlPath}`;
			let params = new HttpParams();
			params = params.set("id", id.toString());
			return this.http.get<void>(url, { params, withCredentials: true });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

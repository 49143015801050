import { Injectable, ViewChild } from "@angular/core";
import { FormGroup, AbstractControl, FormControl, FormArray, ValidationErrors } from "@angular/forms";

import { OverlayComponent } from "@shared/overlay/overlay.component";
import { OverlayService } from "@core/overlay.service";
import { TranslationService } from "@core/translation.service";

export interface ErrorExceptionMessage {
	error?: string;
	exceptionMessage?: string;
}

@Injectable()
export class UserErrorsService {
	@ViewChild(OverlayComponent, { static: false }) public readonly overlay?: OverlayComponent;

	constructor(
		private overlayService: OverlayService,
		private translateService: TranslationService,
	) {
	}

	public displayErrors(form: FormGroup | FormArray, overlay?: OverlayComponent, errorsMap?: Map<string, string>, isChangeZ = false): void {
		const errors = this.getFormValidationErrors(form);
		if (errors.length) {
			const parsedErrors = errors.map((error: { [x: string]: AbstractControl & { controlName: string } }) => Object.keys(error)
				.map((fieldName: string) => Object.keys(error[fieldName].errors || {})
					.map((key: string) => {
						if (!errorsMap)
							switch (key) {
								case "notNumber": {
									return `Значение поля "${error[fieldName].controlName || fieldName}" должно быть больше нуля.`;
								}
								case "maxlength": {
									return `Значение поля ${error[fieldName].controlName || fieldName} должно быть не более ${((error[fieldName].errors || {})[key] || {})["requiredLength"] || ""} символов.`;
								}
								case "minlength": {
									return `Значение поля "${error[fieldName].controlName || fieldName}" должно быть более ${((error[fieldName].errors || {})[key] || {})["requiredLength"] || ""} символов.`;
								}
								case "wrongLengthGLN": {
									return `Значение поля ${error[fieldName].controlName || fieldName} должно быть ровно 13 символов.`;
								}
								case "wrongLength": {
									return `Значение поля "${error[fieldName].controlName || fieldName}" должно быть ровно ${error[fieldName].errors?.length || {}} символов.`;
								}
								case "max": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "length": {
									return;
								}
								case "checkPeriod": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "notEdsProxyPort": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "isMoreOfToday": {
									return `"${error[fieldName].controlName || fieldName}" ${this.translateService.getTranslation(key)}.`;
								}
								case "isSmallerOfToday": {
									return `"${error[fieldName].controlName || fieldName}" ${this.translateService.getTranslation(key)}.`;
								}
								case "removeSpaceField": {
									return `"${error[fieldName].controlName || fieldName}" содержатся пробелы.`;
								}
								case "notPositiveEdsProxyPort": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "pattern": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "lessThan": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "emailError": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "emailLatinError": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "maxOrdersMarksValue": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "validationValidity": {
									return `${this.translateService.getTranslation(key)}.`;
								}
								case "formAdditionalFieldError": {
									const fieldValue = ((error[fieldName].errors || {})[key] || {})["value"] || "";
									return `В добавленном дополнительном поле не может быть использован код ${fieldValue}.`;
								}
								case "requiredTrue": {
									return `${this.translateService.getTranslation(key)} ${this.translateService.getTranslation(fieldName)}.`;
								}
								case "emptyAroundDot": {
									return `${this.translateService.getTranslation(key)} "${error[fieldName].controlName || fieldName}".`;
								}
								case "notGTIN": {
									return `${this.translateService.getTranslation(key)} "${error[fieldName].controlName || fieldName}": ${error[fieldName].value}.`;
								}
								case "isCheckNumberIntervalError": {
									const firstNumber = ((error[fieldName].errors || {})[key] || {})["firstNumber"] || "0";
									const secondNumber = ((error[fieldName].errors || {})[key] || {})["secondNumber"] || "0";
									return `Значение поля ${error[fieldName].controlName || fieldName} должно быть ${firstNumber} и более и не больше ${secondNumber}.`;
								}
								case "invalidDeliveryNoteDate": {
									const isMax = ((error[fieldName].errors || {})[key] || {})["isMax"];
                                    const controlDate = ((error[fieldName].errors || {})[key] || {})["controlDate"] || "";
                                    const direction = isMax ? "больше" : "меньше";
                                    return `Дата не может быть ${direction}, чем ${controlDate}.`
								}
								case "requiredByPositionError": {
									const position = (error[fieldName].errors || {})[key]["position"] || "0";
									return `В товарной позиции №${position} не заполнено обязательное поле "${error[fieldName].controlName || fieldName}".`;
								}
								case "minValueError": {
									const position = (error[fieldName].errors || {})[key]["position"] || "0";
									return `${this.translateService.getTranslation(key).replace("POSITION", position)}`;
								}
								default: {
									return `${this.translateService.getTranslation(key)} "${error[fieldName].controlName || fieldName}".`;
								}
							}
						else {
							return (errorsMap.get(key) || "").replace("?", error[fieldName].controlName);
						}
					})));
			(overlay || this.overlayService).showNotification$((parsedErrors || []).map(e =>
				e.map(m => m.length > 1 ? e.map(m => m.filter(x => x).join("\n")) : e.join("\n"))).join("\n"), "error", undefined, undefined, isChangeZ);
			return;
		}
	}

	public getFormValidationErrors(formGroup?: FormGroup | FormControl | FormArray, validationErrors?: { [x: string]: AbstractControl }[]): ValidationErrors[] {
		const errors: { [x: string]: AbstractControl }[] = validationErrors || [];
		if (formGroup && formGroup instanceof FormGroup)
			Object.keys(formGroup.controls).forEach(key => {
				const control = formGroup.get(key);
				const controlErrors: ValidationErrors | null = control ? control.errors : null;
				if (controlErrors != null && control) {
					errors.push({ [key]: control });
				}
				this.getFormValidationErrors(control as FormGroup | FormControl, errors);
			});
		if (formGroup && formGroup instanceof FormArray) {
			for (const key in formGroup.controls) {
				this.getFormValidationErrors((formGroup.controls as any)[key] as FormGroup | FormControl, errors);
			}
		}
		return errors;
	}

	public errorExceptionMessage(errors?: ErrorExceptionMessage): string {
		const errorMessageValue = errors?.error || "";
		const exceptionMessageValue = errors?.exceptionMessage ? this.translateService.getTranslation("dataMarkError") + " " + errors?.exceptionMessage : "";
		return errorMessageValue + (errorMessageValue && exceptionMessageValue ? "\n" : "") + exceptionMessageValue;
	}
}

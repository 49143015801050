<div (appClickOutside)="onClickOutside()" [ngClass]="shangePosition ? 'select-date-position-bottom' : 'select-date-position-top'">
	<input type="text" [class.error]="showError" (click)="expand=!expand" readonly="readonly"
		[attr.disabled]="isDisabled ? isDisabled : null" [ngClass]="{'expand': expand}"
		[placeholder]="placeholder || hidePlaceholder ? placeholder : 'Не выбрано'"
		[value]="withTime && !hideTime && value ? (value | date : 'short') : value ? (value | date : 'shortDate') : null">

	<app-icon (click)="expand=!expand">insert_invitation</app-icon>

	<app-select-date 
		*ngIf="expand && !isDisabled" 
		[value]="value"
		[withTime]="withTime"
		[hideTime]="hideTime"
		[dayLimit]="dayLimit"
		[isDateLimit]="isDateLimit"
		[errorOverlay]="errorOverlay"
		(appSetNewDate)="setNewDate($event)" (appClear)="clear()" (appSetTime)="setTime($event)"
		(appEnter)="selectDate($event)">
	</app-select-date>
</div>

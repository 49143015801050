import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs/internal/observable/of";
import { Observable } from "rxjs/internal/Observable";
import { createSelector, Store } from "@ngrx/store";
import { UserState } from "@app/user/user.reducer";
import { UserType } from "@helper/abstraction/user";
import { Role } from "@helper/abstraction/roles";
import { notNull } from "@helper/operators";

@Injectable()
export class UserTypeService {
	constructor(
		public readonly store: Store<UserState>
	) { }

	public checkUserTypePermission(userTypes: UserType[]): Observable<boolean> {
		const selectUserState = (appState: any): UserState => appState.user;
		const selectUserType = createSelector(selectUserState, (state: UserState): UserType | undefined => state.userType);

		return this.store.select(selectUserType).pipe(notNull(), switchMap(userType => {
			if (userTypes.includes(userType))
				return of(true);
			else
				return of(false);
		}));
	}

	public checkUserRolePermission(userRole: string, showTab = false): Observable<boolean> {
		const section = (appState: any): UserState => appState.user;
		const selectRoles = createSelector(section, (state: UserState): Role[] => state.roles);
		const obs = this.store.select(selectRoles).pipe(
			notNull(),
			switchMap(role => {
				return of(showTab ? role.map(r => r.id).includes(userRole) : !role.map(r => r.id).includes(userRole));
			})
		);

		return obs;
	}
}

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable()
export class CTTEnvironmentGuard implements CanActivate {

	constructor(private router: Router) { }

	public canActivate(): boolean {
		if (environment.project !== "CTT") {
			this.router.navigate(["user/login"]);
			return false;
		} else{
			return true;
		}
	}
}

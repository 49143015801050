import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { StorageCreate, StorageCreateDto } from "@helper/abstraction/storages";
import { FindInfoForCreatingCode } from "@app/user/customization/my-organization/storages/storage-store/storage";

export class Storages {
	public list = {
		put$: (storage: StorageCreateDto): Observable<StorageCreateDto> => {
			const url = `${this.config.api.root}/storage`;
			return this.http.put<StorageCreateDto>(url, storage, { withCredentials: true });
		},

		post$: (storageIds: number): Observable<boolean> => {
			const url = `${this.config.api.root}/storage/canBeDeleted`;
			return this.http.post<boolean>(url, storageIds, { withCredentials: true });
		},

		delete$: (storageIds: number[]): Observable<string[]> => {
			const url = `${this.config.api.root}/storage`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: storageIds,
				withCredentials: true
			};
			return this.http.delete<string[]>(url, options);
		},

		confirmDeleting$: (storageIds: number[]): Observable<void> => {
			const url = `${this.config.api.root}/storage/deleteFinal`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: storageIds,
				withCredentials: true
			};
			return this.http.delete<void>(url, options);
		},

		findInfoForCreating$: (gln: string): Observable<FindInfoForCreatingCode> => {
			const url = `${this.config.api.root}/storage/findInfoForCreating`;
			let params = new HttpParams();
			if (gln) {
				params = params.set("gln", gln);
			}
			return this.http.get<FindInfoForCreatingCode>(url, { params });
		},

		createPartyStorage$: (storage: StorageCreate): Observable<void> => {
			const url = `${this.config.api.root}/storage/createPartyStorage`;
			return this.http.post<void>(url, storage, { withCredentials: true });
		},

		editPartyStorage$: (storage: StorageCreate): Observable<void> => {
			const url = `${this.config.api.root}/storage/editAddress`;
			return this.http.put<void>(url, storage, { withCredentials: true });
		},

		actualizeAddressStorage$: (storageGln: string): Observable<string> => {
			const url = `${this.config.api.root}/storage/actualizeAddress`;
			let params = new HttpParams();
			params = params.set("gln", storageGln);
			return this.http.get<string>(url, { responseType: "text" as "json", params, withCredentials: true });
		},

		getStoragesList$: (storageGln: string[]): Observable<StorageCreateDto[]> => {
			const url = `${this.config.api.root}/storage/list`;
			const options = {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true
			};
			return this.http.post<StorageCreateDto[]>(url, storageGln, options);
		},

		addStorageForReceiver$: (storage: StorageCreateDto): Observable<StorageCreateDto> => {
			const url = `${this.config.api.root}/storage/addForReceiver`;
			return this.http.put<StorageCreateDto>(url, storage, { withCredentials: true });
		},
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

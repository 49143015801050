import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { UserBackendService } from "@app/user/user-core/services/user-backend.service";
import { OverlayService } from "@core/overlay.service";
import { PendingComponent } from "@shared/pending/pending.component";

@Component({
	selector: "app-crypto-mobile-popup",
	templateUrl: "./crypto-mobile-popup.component.html",
	styleUrls: ["./crypto-mobile-popup.component.scss"]
})
export class CryptoMobilePopupComponent implements OnInit {
	public progressUrl = "";
	public showIFrame = true;
	public showMessage?: string;
	public pending = false;
	public isFirst = true;

	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		private sanitizer: DomSanitizer,
		private userBackendService: UserBackendService,
		private changeDetector: ChangeDetectorRef,
		private overlayService: OverlayService
	) {
		window.addEventListener("message", this.listener);
	}

	public ngOnInit(): void {
		this.setData();
	}

	public listener = (event: any): any => {
		if (event.data?.hasOwnProperty("method") && this.isFirst) {
			this.openObservable(event.data);
		}
	};

	public get url(): any {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.progressUrl);
	}

	public ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
		window.removeEventListener("message", this.listener);
	}

	public close(): void {
		this.ref.close();
	}

	private openObservable(data: any): void {
		this.isFirst = false;
		this.showIFrame = false;
		let obs = null;
		if (data?.method === "sign") {
			obs = this.userBackendService.crypto.signCryptoMobile.post$(data?.id);
		}
		if (!obs) {
			throw "no method from service";
		}

		this.openLoadingPopup(true);
		this.changeDetector.detectChanges();
		obs
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(value => {
				this.openLoadingPopup(false);
				this.showMessage = value.status;
				this.changeDetector.detectChanges();
				if (value.status === "COMPLETED" || value.status === "CANCELLED") {
					this.close();
				}
			}, () => {
				this.openLoadingPopup(false);
				this.changeDetector.detectChanges();
			});
	}
	
	private setData(): void {
		this.config.width = "1100px";
		this.config.header = "Подписать ЭЦП-М";
		for (let k in this.config.data) {
			(this as any)[k] = this.config.data[k];
		}
	}

	private openLoadingPopup(checkVersion: boolean): void {
		if (checkVersion) {
			this.overlayService.ref = this.overlayService.dialog.open(PendingComponent, {
				data: { description: "SIGN" },
				showHeader: false,
				width: "100%",
				height: "100%"
			});
		} else {
			if (this.overlayService.ref || this.overlayService.dialog) this.overlayService.ref?.close();
		}
	}
}

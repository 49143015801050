import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Einvoice as IEinvoice } from "@helper/abstraction/einvoice";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs";
import { DraftDto } from "@helper/abstraction/draft";
import { DocumentFromAnotherParams, DocumentType, KindByMessageType, MassReport } from "@helper/abstraction/documents";

export class Einvoice extends DocumentController<IEinvoice> {
	public get apiUrl(): string { return `${this.config.api.einvoice}`; }

	public getEinvoiceResponse = {
		get$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
			const url = `${this.apiUrl}/${documentId}/response`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.get<DraftDto>(url, { withCredentials: true, params });
		}
	};

	public cancelEinvoice = {
		get$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
			const url = `${this.apiUrl}/${documentId}/cancel`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.get<DraftDto>(url, { withCredentials: true, params });
		}
	};

	public checkSign = {
		get$: (documentId: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${documentId}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	public deleteMass = {
		delete$: (documentType: DocumentType, ids: string[]): Observable<MassReport> => {
			const url = `${this.config.api.einvoice}/draft/delete/bulk`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: ids,
				withCredentials: true
			};
			return this.http.delete<MassReport>(url, options);
		}
	};

	public getDocumentFromAnother = {
		post$: <T extends DocumentFromAnotherParams>(body: T, isVersion = false): Observable<KindByMessageType<T["destinationDocumentType"]>> => {
			const url = `${this.handleApi(isVersion)}/${body.id}/getDocumentFromAnother`;
			delete body.id;
			delete body.sourceDocumentType;
			return this.http.post<KindByMessageType<T["destinationDocumentType"]>>(url, body, { withCredentials: true });
		}
	};

	private handleApi(isVersion: boolean): string {
		return this.config.handleVersionPath(this.config.api.einvoice, isVersion ? undefined : "");
	}

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}

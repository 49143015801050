import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from "@angular/core";
import { take } from "rxjs/operators";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

import { SseService } from "@app/user/user-core/server-sent-events.service";

export type ProcessStatus =
	"PENDING" | "CREATE_REGISTRY" | "OK" |
	"SAVE" | "SEND" | "SIGN" | "ERROR" |
	"CANCEL" | "DELETE" | "CHECK_VERSION" |
	"CREATE_INVITATION" | "LOADING_FILE" | "SC_CRYPTO_ANSWER" |
	"REQUEST_PROCESSING" | "EDIT_INVITATION" | "GET_MARKS_PARAMS" |
	"AGGREGATION" | "DISAGGREGATION" | "WRITE_OF" | "SAVE_FILE";

@Component({
	selector: "app-pending",
	templateUrl: "./pending.component.html",
	styleUrls: ["./pending.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingComponent implements OnInit, OnDestroy {
	@Input() public description?: ProcessStatus;
	@Input() public isBlackTheme = false;
	public timePending: any;

	constructor(
        public config: DynamicDialogConfig,
		private sseService: SseService,
	) { }

	public ngOnInit(): void {
		if (this.config?.data?.description) {
			this.description = this.config?.data?.description;
		}
		this.timePending = setTimeout(() => {
			this.sseService.eventSource?.close();
		}, 10000);
	}

	public ngOnDestroy(): void {
		if (this.timePending) clearTimeout(this.timePending);
		if (this.sseService.eventSource?.readyState === 2) {
			this.sseService.getServerSentEvent()
				.pipe(take(1))
				.subscribe();
		}
	}
}

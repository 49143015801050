<section>
	<main>
		<div class="section">
			<ng-container *ngIf="!isDocument; else documentText">
				Для корректной привязки ЭЦП к учетной записи пользователя необходимо установить актуальную версию (<i>v.{{ actualVersion }}</i>) программного комплекса <i>SC-CryptoProxy</i>.
				<div class="block">Скачать последнюю версию <i>SC-CryptoProxy</i> можно тут же в разделе "Мой профиль".</div>
			</ng-container>
		</div>
		<ng-template #documentText>
			Для корректного подписания электронного документа (сообщения) необходимо установить актуальную версию (<i>v.{{ actualVersion }}</i>) программного комплекса <i>SC-CryptoProxy</i>.
			<div class="block">
				Последнюю версию <i>SC-CryptoProxy</i> можно скачать по <span class="link" (click)="goLink()">ссылке</span> либо в разделе "Мой профиль".
			</div>
			<div class="block">
				<!-- Инструкцию по установке и настройке <i>SC-CryptoProxy</i> можно прочитать перейдя по следующей <span class="link" (click)="goLink('help')">ссылке</span>. -->
				Инструкцию по установке и настройке <i>SC-CryptoProxy</i> можно прочитать в справке.
			</div>
		</ng-template>
	</main>
	<footer>
		<button appSkin="action no-shadow bold bordered" type="button" (click)="close(false)">Закрыть</button>
	</footer>
</section>

import { Component, OnDestroy, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { UserErrorsService } from "@app/user/user-core/services/user-errors.service";
import { UserState } from "@app/user/user.reducer";
import { OverlayService } from "@core/overlay.service";
import { ValidatorsUtil } from "@helper/validators-util";
import { createSelector, select, Store } from "@ngrx/store";
import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { OverlayComponent } from "@shared/overlay/overlay.component";

@Component({
	selector: "app-ewaybill-create-storage-popup",
	templateUrl: "./ewaybill-create-storage-popup.component.html",
	styleUrls: ["./ewaybill-create-storage-popup.component.scss"]
})
export class EwaybillCreateStoragePopupComponent extends DefaultPopupComponent implements OnDestroy {
	public form: FormGroup;
	public pending$: Observable<boolean | undefined>;
	public process$ = new Subject<string>();
	private unsubscribe$$: Subject<void> = new Subject<void>();
	@ViewChild("notification", { static: false }) public overlayComponent?: OverlayComponent;

	constructor(
		private formBuilder: FormBuilder,
		private store: Store,
		private userErrorsService: UserErrorsService,
		private overlayService: OverlayService,
	) {
		super();
		const user = (appState: any): UserState => appState.user;
		const getPending = createSelector(user, (state: UserState): boolean | undefined => state.popupPending);
		this.pending$ = this.store.pipe(select(getPending));
		this.form = this.formBuilder.group({
			gln: [null, [Validators.required, ValidatorsUtil.checkLengthGLN(), ValidatorsUtil.checkSumGLN()]],
		});
	}

	public add(): void {
		if (this.form.invalid) {
			ValidatorsUtil.triggerValidation(this.form);
			this.userErrorsService.displayErrors(this.form, this.overlayComponent);
			return;
		}
		this.process$.next(this.form.get("gln")?.value);
	}

	public ngOnDestroy(): void {
		this.overlayService.clear();
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { ConfigurationService } from "@core/configuration.service";
import { AttachSignParams, CertificatesCryptoDto, DetachedCryptoDto, MobileEdsAuth, MobileEdsMsg, MobileEdsOperation, SignAParams, SignedSCCryptoDto } from "@helper/abstraction/sccrypto";
import { DraftDto } from "@helper/abstraction/draft";
import { defer } from "rxjs";

export class Crypto {
	public get apiUrl(): string { return `${this.config.signingServiceNew}/SCCrypto`; }
	public get apiMobile(): string { return `${this.config.api.root}/cryptomobile`; }
	public get api(): string { return `${this.config.api.user}`; }
	public get configApi(): string { return `${this.config.api.root}`; }

	public certificates = {
		get$: (): Observable<CertificatesCryptoDto> => {
			const url = `${this.apiUrl}/ra/certs`;
			return this.http.get<CertificatesCryptoDto>(url);
		}
	};

	public signA = {
		post$: (params: SignAParams): Observable<SignedSCCryptoDto> => {
			const url = `${this.apiUrl}/ra/signa`;
			return this.http.post<SignedSCCryptoDto>(url, params);
		}
	};

	public getSignRequest = {
		get$: (id: number, isVersion = false): Observable<MobileEdsOperation> => {
			const url = `${this.handleApi(isVersion)}/signRequest/${id}`;
			return this.http.get<MobileEdsOperation>(url);
		}
	};

	public signatureMobileTypeOperation = {
		post$: (mobileEdsMsg: MobileEdsMsg, type: string, operation: string, isVersion = false): Observable<MobileEdsOperation> => {
			const url = `${this.handleApi(isVersion)}/${type}/${operation}`;
			return this.http.post<MobileEdsOperation>(url, mobileEdsMsg, { withCredentials: true });
		}
	};

	public authorizeCryptoMobile = {
		post$: (): Observable<MobileEdsAuth> => {
			const url = `${this.apiMobile}/authorize`;
			return this.http.post<MobileEdsAuth>(url, null, { withCredentials: true });
		}
	};

	public attachCryptoMobile = {
		post$: (mobileEds: MobileEdsAuth): Observable<MobileEdsAuth> => {
			const url = `${this.apiMobile}/attach`;
			return this.http.post<MobileEdsAuth>(url, mobileEds, { withCredentials: true });
		}
	};

	public detachCryptoMobile = {
		post$: (): Observable<void> => {
			const url = `${this.apiMobile}/detach`;
			return this.http.post<void>(url, null, { withCredentials: true });
		}
	};

	public signD = {
		post$: (draftDto: DraftDto): Observable<SignedSCCryptoDto> => {
			const url = `${this.apiUrl}/ra/signd`;
			let dataWithUNP;
			const data = {
				Auth: {
					CryptoType: 1,
					KeyID: draftDto.publicId,
					Password: draftDto.password
				},
				Data: draftDto.xmlByteDate,
				DataB64: draftDto.xmlBase64,
				OptAddAllCert: true,
				OptAddCert: true
			};
			if (data.DataB64) {
				delete data.Data;
			}
			if (draftDto.unp)
				dataWithUNP = {
					...data,
					Attributes: [{
						OID: "1.2.112.1.2.1.1.1.1.2",
						Text: draftDto.unp
					}]
				};
			return this.http.post<SignedSCCryptoDto>(url, dataWithUNP || data);
		}
	};

	public bindCertificateToUser = {
		put$: (params: AttachSignParams): Observable<CertificatesCryptoDto> => {
			const url = `${this.api}/attachSign`;
			return this.http.put<CertificatesCryptoDto>(url, params, { withCredentials: true });
		}
	};

	public unbindCertificateByUser = {
		put$: (): Observable<void> => {
			const url = `${this.api}/detachSign`;
			return this.http.put<void>(url, {}, { withCredentials: true });
		}
	};

	public getTokenForCertificateBinding = {
		get$: (): Observable<number[]> => {
			const url = `${this.api}/signToken`;
			return this.http.get<{ token: [number] }>(url, { withCredentials: true }).pipe(map(tokenObj => tokenObj.token));
		}
	};

	public getActualVersionSC_Crypto = {
		get$: (): Observable<string> => {
			const url = `${this.configApi}/config/signConfig`;
			return this.http.get<{ cryptoProxyVersion: string }>(url, { withCredentials: true }).pipe(map(versionObj => versionObj.cryptoProxyVersion));
		}
	};

	public callbackCryptoMobile = {
		post$: (query: string): Observable<any> => {
			const url = `api/v1/cryptomobile/oauth/callback${query}`;
			return this.http.post<any>(url, { withCredentials: true });
		}
	};

	public signCryptoMobile = {
		post$: (id: number): Observable<any> => {
			return this.http.post<any>(`api/v1/cryptomobile/oauth/sign/${id}`, null);
		}
	};

	public cryptcreator = {
		// TODO
		getSignedEcmr$: (ecmrData: DetachedCryptoDto, url: string): Observable<any> => { // TODO data type
			// const url = "http://192.168.246.172:8002/cryptcreator";
			return defer(()=>{
				return fetch(url, {
					  method: "POST",
					  body: JSON.stringify(ecmrData)
				  })
				  .then(response => response.json())
				  .then(json => {
					  return json;
				  })
			  });
		}
		// TODO
	};

	private handleApi(isVersion: boolean): string {
		return this.config.handleVersionPath(this.apiMobile, isVersion ? undefined : "");
	}

	constructor(
		private config: ConfigurationService,
		private http: HttpClient
	) { }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: "app-svg-aggregate",
  templateUrl: "./svg-aggregate.component.html",
  styleUrls: ["./svg-aggregate.component.scss"]
})
export class SvgAggregateComponent {
  @Input() public textValue?: string | number;
}

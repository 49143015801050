import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { MnsManagementListParams, MnsManagementList, MnsManagementUserDto } from "@helper/abstraction/mns-management";
import { Observable, Subject, Subscription } from "rxjs";
import { EwaybillCountByPartyFilter, EwaybillAmountByReceiverFilter, EwaybillAmountByShipperFilter, CompanyWorksWithEwaybillFilter, EwaybillCountByShipperFilter, EwaybillListByShipperReceiverFilter, TotalEwaybillCountFilter, TotalEwaybillListFilter, ReportType, ShipperListAbroudFilter, ReceiverListAbroudFilter } from "@helper/abstraction/reports";
import { MnsRequest, MnsRequestParams, CommonInfo } from "@helper/abstraction/mns-request";
import { MnsEwaybillFilter, MnsEwaybill, PartyDto, MnsParty } from "@helper/abstraction/mns-ewaybill";
import { MnsReportList, MnsPartyDro } from "@helper/abstraction/mns-reports";
import { RequestForm } from "@helper/abstraction/request";
import { Store, createSelector, select } from "@ngrx/store";
import { UserType } from "@helper/abstraction/user";
import { skip, takeUntil } from "rxjs/operators";
import { DraftDto } from "@helper/abstraction/draft";
import { MnsErrorTransitFilter, MnsErrorTransitPage, MnsTransitFilter } from "@helper/abstraction/mns-transit";
import { MnsState } from "@app/mns/mns-store/mns.reducer";

export class Mns {
	public userType?: UserType;

	public get apiUserInspectorUrl(): string {
		if (this.userType) {
			return ["GTK_USER", "GTK_ADMIN"].includes(this.userType) ? `${this.config.api.user}/gtk` : `${this.config.api.user}/mns`;
		} else {
			throw Error("No userType!");
		}
	}

	public get apiMnsUrl(): string { return `${this.config.api.mns}`; }

	public report = {
		ewaybillCountByParty: {
			post$: (reportFilter: EwaybillCountByPartyFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/EWAYBILL_COUNT_BY_PARTY`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		ewaybillAmountByReceiver: {
			post$: (reportFilter: EwaybillAmountByReceiverFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/EWAYBILL_AMOUNT_BY_RECEIVER`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		ewaybillAmountByShipper: {
			post$: (reportFilter: EwaybillAmountByShipperFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/EWAYBILL_AMOUNT_BY_SHIPPER`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		companyWorksWithEwaybill: {
			post$: (reportFilter: CompanyWorksWithEwaybillFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/COMPANY_WORKS_WITH_EWAYBILL`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		ewaybillCountByShipper: {
			post$: (reportFilter: EwaybillCountByShipperFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/EWAYBILL_COUNT_BY_SHIPPER`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		ewaybillListByShipperReceiver: {
			post$: (reportFilter: EwaybillListByShipperReceiverFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/EWAYBILL_LIST_BY_SHIPPER_RECEIVER`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		totalEwaybillCount: {
			post$: (reportFilter: TotalEwaybillCountFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/TOTAL_EWAYBILL_COUNT`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		totalEwaybillList: {
			post$: (reportFilter: TotalEwaybillListFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/TOTAL_EWAYBILL_LIST`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		shipperListAbround: {
			post$: (reportFilter: ShipperListAbroudFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/SHIPPER_LIST_ABROUD`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		receiverListAbroud: {
			post$: (reportFilter: ReceiverListAbroudFilter): Observable<void> => {
				const url = `${this.apiMnsUrl}/report/RECEIVER_LIST_ABROUD`;
				return this.http.post<void>(url, reportFilter, { withCredentials: true });
			}
		},
		shipper: {
			list: {
				post$: (shipperParams: { search: any; page: number; size: number }): Observable<MnsPartyDro> => {
					const url = `${this.apiMnsUrl}/report/shipper/list`;
					let params = new HttpParams();
					params = params.set("page", shipperParams.page.toString());
					params = params.set("size", shipperParams.size.toString());

					return this.http.post<MnsPartyDro>(url, shipperParams.search, { params, withCredentials: true });
				},
			}
		},
		receiver: {
			list: {
				post$: (receiverParams: { search: any; page: number; size: number }): Observable<MnsPartyDro> => {
					const url = `${this.apiMnsUrl}/report/receiver/list`;
					let params = new HttpParams();
					params = params.set("page", receiverParams.page.toString());
					params = params.set("size", receiverParams.size.toString());

					return this.http.post<MnsPartyDro>(url, receiverParams.search, { params, withCredentials: true });
				},
			}
		},
		list: {
			get$: (report: { type: ReportType; name?: string }): Observable<MnsReportList[]> => {
				const url = `${this.apiMnsUrl}/report/${report?.type}`;
				let params = new HttpParams();
				if (report.name) {
					params = params.set("reportName", report.name);
				}
				return this.http.get<MnsReportList[]>(url, { params, withCredentials: true });
			}
		},
		download: {
			get$: (id: number): Observable<HttpResponse<Blob>> => {
				const url = `${this.apiMnsUrl}/report/download/${id}`;
				return this.http.get<Blob>(url, {
					withCredentials: true,
					responseType: "blob" as "json",
					observe: "response"
				});
			}
		},
	};

	public request = {
		downloadRequest: {
			get$: (id: number): Observable<HttpResponse<Blob>> => {
				const url = `${this.apiMnsUrl}/request/downloadRequest/${id}`;
				return this.http.get<Blob>(url, {
					withCredentials: true,
					responseType: "blob" as "json",
					observe: "response"
				});
			}
		},
		downloadResponse: {
			get$: (id: number): Observable<HttpResponse<Blob>> => {
				const url = `${this.apiMnsUrl}/request/downloadResponse/${id}`;
				return this.http.get<Blob>(url, {
					withCredentials: true,
					responseType: "blob" as "json",
					observe: "response"
				});
			}
		},
		saveTaxRequest: {
			post$: (signature: number[], xmlBody: string, signatureBase64: string): Observable<void> => {
				const url = `${this.apiMnsUrl}/request/saveTaxRequest`;
				return this.http.post<void>(url, { signature, xmlBody, signatureBase64 }, { withCredentials: true });
			}
		},
		list: {
			post$: (params: MnsRequestParams): Observable<MnsRequest[]> => {
				const url = `${this.apiMnsUrl}/request/list`;
				return this.http.post<MnsRequest[]>(url, params, { withCredentials: true });
			}
		},
		processingStatuses: {
			get$: (documentTypeId: string): Observable<CommonInfo[]> => {
				const url = `${this.apiMnsUrl}/request/${documentTypeId}/processing-statuses/list`;
				return this.http.get<CommonInfo[]>(url, { withCredentials: true });
			}
		},
		createXml: {
			post$: (request: RequestForm, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.apiMnsUrl}/request/createXml`;
				const data = {
					deliveryNoteDateFrom: request.deliveryDateFrom,
					deliveryNoteDateTo: request.deliveryDateTo,
					deliveryNoteNumber: request.deliveryNumber,
					msgTypeList: request.waybillType === "ALL" ? ["BLRDLN", "BLRWBL"] : [`${request.waybillType}`],
					processingStatus: request.waybillStatus?.id,
					receiverList: !request.receiver ? [] : request.receiver,
					shipperList: !request.shipper ? [] : request.shipper
				};
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, data, { withCredentials: true, params });
			}
		}
	};

	public create = {
		post$: (user: MnsManagementUserDto): Observable<void> => {
			const url = `${this.apiUserInspectorUrl}`;
			return this.http.post<void>(url, user, { withCredentials: true });
		}
	};

	public addUserPassword = {
		put$: (data: { password: string, userId: number }): Observable<void> => {
			const url = `${this.apiUserInspectorUrl}/savePassword`;
			return this.http.put<void>(url, data, { withCredentials: true });
		}
	};

	public edit = {
		put$: (user: MnsManagementUserDto): Observable<void> => {
			const url = `${this.apiUserInspectorUrl}`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public restore = {
		put$: (user: number[]): Observable<void> => {
			const url = `${this.apiUserInspectorUrl}/restore`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public list = {
		post$: (params: MnsManagementListParams, userType?: UserType): Observable<MnsManagementList> => {
			let startUrl;
			if (userType) {
				startUrl = ["GTK_USER", "GTK_ADMIN"].includes(userType) ? `${this.config.api.user}/gtk` : `${this.config.api.user}/mns`;
			}
			const url = `${startUrl || this.apiUserInspectorUrl}/list`;
			return this.http.post<MnsManagementList>(url, params, { withCredentials: true });
		}
	};

	public statuses = {
		list: {
			get$: (): Observable<any> => {
				const url = `${this.config.api.root}/user/statuses/list`;
				return this.http.get<any>(url, { withCredentials: true });
			}
		}
	};

	public block = {
		put$: (user: number[]): Observable<void> => {
			const url = `${this.apiUserInspectorUrl}/block`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public unblock = {
		put$: (user: number[]): Observable<void> => {
			const url = `${this.apiUserInspectorUrl}/unblock`;
			return this.http.put<void>(url, user, { withCredentials: true });
		}
	};

	public EWAYBILL = {
		list: {
			post$: (statisticParams: MnsEwaybillFilter): Observable<MnsEwaybill[]> => {
				const url = `${this.apiMnsUrl}/EWAYBILL/list`;
				const { page, size, ...filterDto } = statisticParams;
				let params = new HttpParams();
				params = params.set("page", page.toString());
				params = params.set("size", size.toString());
				return this.http.post<MnsEwaybill[]>(url, filterDto, { params, withCredentials: true });
			}
		},
		organizations: {
			get$: (statisticParams: { page: number; size: number; searchText?: string }): Observable<MnsParty[]> => {
				const url = `${this.apiMnsUrl}/EWAYBILL/organizations/list`;
				const { page, size, searchText } = statisticParams;
				let params = new HttpParams();
				params = params.set("page", page.toString());
				params = params.set("size", size.toString());
				searchText ? params = params.set("organizationName", searchText) : "";
				return this.http.get<PartyDto[]>(url, { params, withCredentials: true });
			}
		}
	};

	public TRANSIT = {
		list: {
			post$: (statisticParams: MnsTransitFilter): Observable<any[]> => {
				const url = `${this.apiMnsUrl}/TRANSIT/list`;
				const { page, size, ...filterDto } = statisticParams;
				let params = new HttpParams();
				params = params.set("page", page.toString());
				params = params.set("size", size.toString());
				return this.http.post<any[]>(url, filterDto, { params, withCredentials: true });
			}
		}
	};

	public error = {
		list: {
			post$: (errorStatisticParams: MnsErrorTransitFilter): Observable<MnsErrorTransitPage> => {
				const url = "api/error/mns/documents/list";
				const { page, size, ...filterDto } = errorStatisticParams;
				const filterData = {
					...filterDto,
					codeTypeList: [{ id: "PKSPT", name: "Ошибка ПК СПТ" }],
					documentTypeList: [{ id: "BLRSPT", name: "Электронные сообщения о транзите" }]
				};
				let params = new HttpParams();
				params = params.set("page", page.toString());
				params = params.set("size", size.toString());
				return this.http.post<MnsErrorTransitPage>(url, filterData, { params, withCredentials: true });
			}
		}
	};

	public errorDownloadArchive = {
		post$: (errorIdList: number[]): Observable<HttpResponse<Blob>> => {
			const url = "api/error/mns/documents/downloadArchive/XML";
			return this.http.post<Blob>(url, errorIdList, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public unsubscribe$$ = new Subject<void>();

	constructor(
		private store: Store,
		private config: ConfigurationService,
		private http: HttpClient,
		private subscription?: Subscription,
	) {
		const user = (appState: any): MnsState => appState.mns;
		const userTypeState = createSelector(user, (state: MnsState): UserType | undefined => state?.userType);
		if (this.subscription)
			this.subscription.unsubscribe();
		this.subscription = this.store.pipe(select(userTypeState)).pipe(skip(1), takeUntil(this.unsubscribe$$)).subscribe(type => this.userType = type);
	}

	public delete$(deleteIds: number[]): Observable<void> {
		const url = `${this.apiUserInspectorUrl}/delete`;
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}),
			body: deleteIds,
			withCredentials: true
		};
		return this.http.delete<void>(url, options);
	}

	public get$(userId: number): Observable<MnsManagementUserDto> {
		const url = `${this.apiUserInspectorUrl}/${userId}`;
		return this.http.get<MnsManagementUserDto>(url, { withCredentials: true });
	}

}

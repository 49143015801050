import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Ewaybill as IEwaybill, ResponseDraftInfo } from "@helper/abstraction/ewaybill";
import { DocumentController } from "./document-controller";
import { DocumentFromAnotherParams, DocumentType, KindByMessageType, MassReport, MultipleSendingDto } from "@helper/abstraction/documents";
import { DraftDto } from "@helper/abstraction/draft";

export interface DocumentForSignDto {
	errorDescription?: string;
	id: number;
	publicId: string;
	subjectName: string;
	xmlBody: string;
	xmlByteDate: number[];
	documentId?: number;
    documentType?: string;
    successful?: boolean;
    xmlBase64?: string;
}

export class Ewaybill extends DocumentController<IEwaybill> {
	public get apiUrl(): string { return `${this.config.api.ewaybill}`; }

	public checkSign = {
		get$: (documentId: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${documentId}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	public getDocumentFromAnother = {
		post$: <T extends DocumentFromAnotherParams>(body: T, isVersion = false): Observable<KindByMessageType<T["destinationDocumentType"]>> => {
			const url = `${this.handleApi(isVersion)}/${body.id}/getDocumentFromAnother`;
			delete body.id;
			delete body.sourceDocumentType;
			return this.http.post<KindByMessageType<T["destinationDocumentType"]>>(url, body, { withCredentials: true });
		}
	};

	public convertToDraftEactdif = {
		post$: <T extends DocumentFromAnotherParams>(body: T): Observable<KindByMessageType<T["destinationDocumentType"]>> => {
			const url = `${this.apiUrl}/${body.id}/convertToDraftEactdif`;
			delete body.id;
			delete body.sourceDocumentType;
			return this.http.post<KindByMessageType<T["destinationDocumentType"]>>(url, body, { withCredentials: true });
		}
	};

	public sendMass = {
		post$: (documentType: DocumentType, ids: MultipleSendingDto, isVersion = false): Observable<MassReport> => {
			const url = `${this.handleApi(isVersion)}/sendEwaybillsByActiveMq`;
			return this.http.post<MassReport>(url, ids, { withCredentials: true });
		}
	};

	public deleteMass = {
		delete$: (documentType: DocumentType, ids: string[], isVersion = false): Observable<MassReport> => {
			const url = `${this.handleApi(isVersion)}/draft/deleteEwaybills`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: ids,
				withCredentials: true
			};
			return this.http.delete<MassReport>(url, options);
		}
	};

	public draft = {
		response: {
			findXml: {
				get$: (responseId: number, isVersion = false, base64?: boolean): Observable<DraftDto> => {
					const url = `${this.handleApi(isVersion)}/draft/response/findXml/${responseId}`;
					let params = new HttpParams();
					if (typeof base64 === "boolean") {
						params = params.set("base64", base64!.toString());
					}
					return this.http.get<DraftDto>(url, { withCredentials: true, params });
				}
			},
			saveBasedOnEwaybill: {
				post$: (ewaybillId: string, isVersion = false): Observable<ResponseDraftInfo> => {
					const url = `${this.handleApi(isVersion)}/draft/response/saveBasedOnEwaybill/${ewaybillId}`;
					return this.http.post<ResponseDraftInfo>(url, null, { withCredentials: true });
				}
			},
			update: {
				post$: (ewaybill: IEwaybill, isValidate = true, isVersion = false): Observable<number> => {
					const url = `${this.handleApi(isVersion)}/draft/response/update`;
					let params = new HttpParams();
					params = params.set("isValidate", isValidate.toString());
					return this.http.post<number>(url, ewaybill, { params, withCredentials: true });
				},
			},
			createBasedOnEwaybillForSign: {
				post$: (body: { ewaybillId: number[]; shipToContact: string }, isVersion = false, base64?: boolean): Observable<DocumentForSignDto[]> => {
					const url = `${this.handleApi(isVersion)}/draft/response/createBasedOnEwaybillForSign/bulk`;
					let params = new HttpParams();
					if (typeof base64 === "boolean") {
						params = params.set("base64", base64!.toString());
					}
					return this.http.post<DocumentForSignDto[]>(url, body, { withCredentials: true, params });
				}
			}
		}
	};

	private handleApi(isVersion: boolean): string {
		return this.config.handleVersionPath(this.config.api.ewaybill, isVersion ? undefined : "");
	}

	public getDraft$(documentId: number): Observable<any> {
		const url = `${this.apiUrl}/draft/${documentId}`;
		return this.http.get<any>(url, { withCredentials: true });
	}

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}

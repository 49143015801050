import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { OverlayService } from "@core/overlay.service";
import { Title } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { resetStore } from "@app/app.reducer";
import { resetManagementList } from "@app/user/customization/customization-store/management.actions";
import { resetDocumentsPage } from "@app/user/documents/documents-store/documents.actions";
import { UserState } from "@app/user/user.reducer";
import { environment } from "src/environments/environment";
import { UserAuthService } from "../services/user-auth.service";
import { UserCookieService } from "../services/user-cookie.service";

@Injectable()
export class UserLogoutGuard implements CanActivate {
	constructor(
		private readonly title: Title,
		private readonly router: Router,
		private readonly authService: UserAuthService,
		private readonly overlayService: OverlayService,
		private userCookieService: UserCookieService,
		private store: Store<UserState>
	) { }

	public canActivate(): boolean {
		this.authService.logout();
		this.store.dispatch(resetDocumentsPage());
		this.store.dispatch(resetStore());
		this.store.dispatch(resetManagementList());
		switch (environment.project) {
			case "BBV":
				this.title.setTitle("Система \"Электронный бланк\"");
				break;
			case "CTT":
				this.title.setTitle("EDI-система СТТ");
				break;
			case "BM":
				this.title.setTitle("Bidmart EDI");
				break;
			case "ME":
				this.title.setTitle("MetaEra.EDI");
				break;
			default:
				this.title.setTitle("EDI");
		}
		this.userCookieService.clearCookies();
		sessionStorage.removeItem("CertificateType");
		this.router.navigate(["/user/login"]);
		return false;
	}
}

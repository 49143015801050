import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { CodesMarkingRequestObject, DestroyMarkCode, ExportMarkingCodeList, MarkCodeOrderFilterDto, MarkCodeOrderListType, MarkingCode, MarkingCodesFilterDto, MarkingCodesList, MarkingsDto, ParamInfoDmDto } from "@app/user/marks/marks-store/marks-types";
import { ConfigurationService } from "@core/configuration.service";
import { Status } from "@helper/abstraction/status";
import { Observable } from "rxjs";

export class Datamark {
	public get apiUrl(): string { return `${this.config.api.datamark}`; }

	public attach = {
		post$: (value: { username: string; password: string }): Observable<{ username: string; password: string }> => {
			const url = `${this.apiUrl}/account/attach`;
			return this.http.post<{ username: string; password: string }>(url, value, { withCredentials: true });
		}
	};

	public detach = {
		post$: (): Observable<void> => {
			const url = `${this.apiUrl}/account/detach`;
			return this.http.post<void>(url, { withCredentials: true });
		}
	};

	public itemGroup = {
		get$: (): Observable<Status[]> => {
			const url = `${this.apiUrl}/itemGroup/list`;
			return this.http.get<Status[]>(url, { withCredentials: true });
		}
	};

	// ------------ Marking Orders ----------------

	public getOrdersList = {
		post$: (filter: MarkCodeOrderFilterDto): Observable<MarkingsDto> => {
			const url = `${this.apiUrl}/order/filteredPage`;
			const { page, size, ...filterDto } = filter;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<MarkingsDto>(url, filterDto, { params, withCredentials: true });
		}
	};

	public createMarksOrder = {
		post$: (ordersCreateList: any[]): Observable<any[]> => {
			const url = `${this.apiUrl}/order/create`;
			return this.http.post<any[]>(url, ordersCreateList, { withCredentials: true });
		}
	};

	public orderByIdReceive = {
		post$: (id: number): Observable<MarkCodeOrderListType[]> => {
			const url = `${this.apiUrl}/order/${id}/receive`;
			return this.http.post<MarkCodeOrderListType[]>(url, null, { withCredentials: true });
		}
	};

	public downloadOrdersById = {
		get$: (id: number): Observable<HttpResponse<Blob>> => {
			const url = `${this.apiUrl}/order/${id}/file`;
			return this.http.get<Blob>(url, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public getOrdersStatusesList = {
		get$: (): Observable<Status[]> => {
			const url = `${this.apiUrl}/order/status/list`;
			return this.http.get<Status[]>(url, { withCredentials: true });
		}
	};

	// ------------ Marking Codes ----------------

	public getMarkingCodesList = {
		post$: (filter: MarkingCodesFilterDto): Observable<MarkingsDto> => {
			const url = `${this.apiUrl}/code/filteredPage`;
			const { page, size, ...filterDto } = filter;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<MarkingsDto>(url, filterDto, { params, withCredentials: true });
		}
	};

	public gtinShipmentFilteredList = {
		post$: (filter: {page: number; size: number; gtin: number; markCodeValue: string}): Observable<MarkingsDto> => {
			const url = `${this.apiUrl}/code/gtinShipment/filteredPage`;
			let params = new HttpParams();
			params = params.set("page", filter.page.toString());
			params = params.set("size", filter.size.toString());
			const body = {
				gtin: filter.gtin,
				markCodeValue: filter.markCodeValue
			};
			return this.http.post<MarkingsDto>(url, body, { params, withCredentials: true });
		}
	};

	public gtinShipmentGtinBulk = {
		post$: (filter: {gtin: string; markCodeIds: number[]}): Observable<MarkingCodesList> => {
			const url = `${this.apiUrl}/code/gtinShipment/${filter.gtin}/bulk`;
			return this.http.post<MarkingCodesList>(url, filter.markCodeIds, { withCredentials: true });
		}
	};


	public disAggregate	= {
		post$: (markCodeIds: number[]): Observable<MarkingCodesList> => {
			const url = `${this.apiUrl}/code/disaggregate/bulk`;
			return this.http.post<MarkingCodesList>(url, markCodeIds, { withCredentials: true });
		}
	};

	public aggregate	= {
		post$: (body: {aggregationCode: string; markCodeIds: number[]}): Observable<MarkingCode> => {
			const url = `${this.apiUrl}/code/aggregate`;
			return this.http.post<MarkingCode>(url, body, { withCredentials: true });
		}
	};

	public getMarkParams = {
		post$: (gtinsList: string[]): Observable<ParamInfoDmDto[]> => {
			const url = `${this.apiUrl}/code/markParam/list`;
			return this.http.post<ParamInfoDmDto[]>(url, gtinsList, { withCredentials: true });
		}
	};

	public destroyBulk	= {
		post$: (body: DestroyMarkCode): Observable<MarkingCodesList> => {
			const url = `${this.apiUrl}/code/destroy/bulk`;
			return this.http.post<MarkingCodesList>(url, body, { withCredentials: true });
		}
	};

	public import	= {
		post$: (importForm: any): Observable<any> => {
			const url = `${this.apiUrl}/code/import`;
			return this.http.post<any>(url, importForm, { withCredentials: true });
		}
	};

	public addCirculation	= {
		post$: (circulationForm: any): Observable<any> => {
			const url = `${this.apiUrl}/code/addCirculation`;
			return this.http.post<any>(url, circulationForm, { withCredentials: true });
		}
	};

	public exportBulk	= {
		post$: (body: ExportMarkingCodeList[]): Observable<HttpResponse<Blob>> => {
			const url = `${this.apiUrl}/code/export/bulk`;
			return this.http.post<Blob>(url, body, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public markBulk	= {
		post$: (body: CodesMarkingRequestObject): Observable<MarkingCodesList> => {
			const url = `${this.apiUrl}/code/mark/bulk`;
			return this.http.post<MarkingCodesList>(url, body, { withCredentials: true });
		}
	};

	public aggregationCode	= {
		get$: (): Observable<any> => {
			const url = `${this.apiUrl}/code/aggregationCode`;
			return this.http.get<string>(url, { withCredentials: true });
		}
	};



	public getOrdersTypesList = {
		get$: (): Observable<Status[]> => {
			const url = `${this.apiUrl}/code/type/list`;
			return this.http.get<Status[]>(url, { withCredentials: true });
		}
	};

	public status = {
		list: {
			get$: (): Observable<any> => {
				const url = `${this.apiUrl}/code/status/list`;
				return this.http.get<any>(url, { withCredentials: true });
			}
		}
	};

	public getCodeById	= {
		get$: (id: number): Observable<any> => {
			const url = `${this.apiUrl}/code/${id}`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public destructionReasonList = {
		get$: (): Observable<any> => {
			const url = `${this.apiUrl}/destructionReason/list`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public productionOrderTypeList	= {
		get$: (): Observable<any> => {
			const url = `${this.apiUrl}/productionOrderType/list`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public documentTypeList = {
		get$: (): Observable<any> => {
			const url = `${this.apiUrl}/documentType/list`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public markMethodList = {
		get$: (): Observable<any> => {
			const url = `${this.apiUrl}/markMethod/list`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public verify = {
		post$: (body: any): Observable<any> => {
			const url = `${this.apiUrl}/code/verify`;
			return this.http.post<any>(url, body, { withCredentials: true });
		}
	};

	constructor(
		private config: ConfigurationService,
		private http: HttpClient
	) {
	}
}

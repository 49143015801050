import { Observable } from "rxjs";
import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DocumentKind, MessageType } from "@helper/abstraction/documents";
import { DraftType, DraftDto, SignedDraft } from "@helper/abstraction/draft";
import { Ewaybill } from "@helper/abstraction/ewaybill";
import { EinvoiceDto } from "@helper/abstraction/einvoice";
import { DocumentType } from "@helper/abstraction/documents";
import { DocumentForSignDto } from "./ewaybill";
import { EwaybillSignBulkDto } from "@app/user/documents/ewaybill/ewaybill";
import { EcmrDtoUI, EcmrForSignDto } from "@helper/abstraction/ecmr";

export class Draft {
	public getXml = {
		get$: (documentId: string, documentTypeId: string): Observable<any> => {
			const url = `${this.config.api.root}/draft/getXml/${documentTypeId}/${documentId}`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public save = {
		post$: <T>(doc: T, docType: DocumentType, isVersion = false): Observable<number> => {
			const url = `${this.handleApi(isVersion)}/${docType}/draft/save`;
			return this.http.post<number>(url, doc, { withCredentials: true });
		}
	};

	public saveCarrierDraft = {
		post$: <T>(doc: T, docType: DocumentType, documentId: number): Observable<number> => {
			const url = `${this.config.api.root}/${docType}/${documentId}/carrier/save`;
			let params = new HttpParams();
			params = params.set("isValidate", "true");
			return this.http.post<number>(url, doc, { withCredentials: true });
		}
	};

	public saveConsigneeDraft = {
		post$: <T>(doc: T, docType: DocumentType, documentId: number): Observable<number> => {
			const url = `${this.config.api.root}/${docType}/${documentId}/consignee/save`;
			let params = new HttpParams();
			params = params.set("isValidate", "true");
			return this.http.post<number>(url, doc, { withCredentials: true });
		}
	};

	public saveResponse = {
		post$: <T>(doc: T, docType: DocumentType): Observable<number> => {
			const url = `${this.config.api.root}/${docType}/draft/response/save`;
			return this.http.post<number>(url, doc, { withCredentials: true });
		}
	};

	public find = {
		get$: <T extends DocumentKind>(draftType: DraftType, draftId: string): Observable<T> => {
			const docType = this.getDocType(draftType);
			const url = `${this.config.api.root}/${docType}/draft/${draftId}`;
			return this.http.get<T>(url, { withCredentials: true });
		},
	};

	public findResponse = {
		get$: <T>(docType: DocumentType, responseId: string): Observable<T> => {
			const url = `${this.config.api.root}/${docType}/draft/response/${responseId}`;
			return this.http.get<T>(url, { withCredentials: true });
		},
	};

	public validateAndSaveSigned = {
		post$: (documentDto: { id: string; xmlBody?: string; signature?: number[] }, docType: DocumentType, isVersion = false): Observable<any> => {
			const url = `${this.handleApi(isVersion)}/${docType}/draft/response/validateAndSaveSigned`;
			return this.http.post(url, documentDto, { withCredentials: true });
		}
	};

	public createWithValidation = {
		post$: (draftType: DraftType, msgEwaybillDraftDto: DocumentKind, isVersion = false, base64?: boolean): Observable<DraftDto> => {
			const docType = this.getDocType(draftType);
			const url = `${this.handleApi(isVersion)}/${docType}/draft/createWithValidation`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.post<DraftDto>(url, msgEwaybillDraftDto, { withCredentials: true, params });
		}
	};

	public createEcmrWithValidation = {
		post$: (draftType: DraftType, msgDraftDto: EcmrDtoUI): Observable<EcmrForSignDto> => {
			const docType = this.getDocType(draftType);
			const url = `${this.config.api.root}/${docType}/draft/createWithValidation`;
			return this.http.post<EcmrForSignDto>(url, msgDraftDto, { withCredentials: true });
		}
	};

	public carrierCreateEcmrWithValidation = {
		post$: (draftType: DraftType, msgDraftDto: any, documentId: number): Observable<EcmrForSignDto> => {
			const docType = this.getDocType(draftType);
			const url = `${this.config.api.root}/${docType}/${documentId}/carrier/createWithValidation`;
			return this.http.post<EcmrForSignDto>(url, msgDraftDto, { withCredentials: true });
		}
	};

	public consigneeCreateEcmrWithValidation = {
		post$: (draftType: DraftType, msgDraftDto: any, documentId: number): Observable<EcmrForSignDto> => {
			const docType = this.getDocType(draftType);
			const url = `${this.config.api.root}/${docType}/${documentId}/consignee/createWithValidation`;
			return this.http.post<EcmrForSignDto>(url, msgDraftDto, { withCredentials: true });
		}
	};

	public createMassWithValidation = {
		post$: (docType: DocumentType, msgEwaybillDraftIdx: EwaybillSignBulkDto, isVersion = false, base64?: boolean): Observable<DocumentForSignDto[]> => {
			const url = `${this.handleApi(isVersion)}/${docType}/draft/createWithValidation/bulk`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.post<DocumentForSignDto[]>(url, msgEwaybillDraftIdx, { withCredentials: true, params });
		}
	};

	public saveSignedMass = {
		post$: (draftType: DocumentType, documentDto: { id: string; xmlBody?: string; signature?: number[] }, isVersion = false): Observable<any> => {
			const url = `${this.handleApi(isVersion)}/${draftType}/draft/saveSigned`;
			return this.http.post(url, documentDto, { withCredentials: true });
		}
	};

	public responseForSign = {
		get$: (draftType: DraftType, draftResponseId: number, base64?: boolean): Observable<DraftDto> => {
			const docType = this.getDocType(draftType);
			const url = `${this.config.api.root}/${docType}/draft/responseForSign/${draftResponseId}`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.get<DraftDto>(url, { withCredentials: true, params });
		}
	};

	public createWithValidationResponse = {
		post$: (docType: DocumentType, doc: DocumentKind): Observable<DraftDto> => {
			const url = `${this.config.api.root}/${docType}/draft/response/createWithValidation`;
			return this.http.post<DraftDto>(url, doc, { withCredentials: true });
		}
	};

	public saveSigned = {
		post$: (draftType: DraftType, documentDto: { id: string; xmlBody?: string; signature?: number[] }, isVersion = false ): Observable<any> => {
			const docType = this.getDocType(draftType);
			const url = `${this.handleApi(isVersion)}/${docType}/draft/saveSigned`;
			return this.http.post(url, documentDto, { withCredentials: true });
		}
	};

	public saveSignedEcmr = {
		post$: (draftType: DraftType, documentId: number, signBase64: string): Observable<any> => {
			const docType = this.getDocType(draftType);
			const url = `${this.config.api.root}/${docType}/draft/${documentId}/saveSigned`;
			return this.http.post(url, signBase64, { withCredentials: true });
		}
	};

	public saveSignedCancel = {
		post$: (id: string, messageType: MessageType, signedXml: string): Observable<void> => {
			const docType = this.getDocType(messageType);
			const url = `${this.config.api.root}/${docType}/draft/saveSignedCancel`;
			return this.http.post<void>(url, { id, xmlBody: signedXml }, { withCredentials: true });
		}
	};

	public blrapn = {
		create2750ByEinvoice: {
			post$: (blrapnDraft: { documentId: number; changeReason: string }, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EINVOICE/create2750ByEinvoice`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, blrapnDraft, { withCredentials: true, params });
			}
		},

		saveSigned2750ByEinvoice: {
			post$: (signBlrapnDraft: { id: number; xmlBody: string; signature: number[] }): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EINVOICE/saveSigned2750ByEinvoice`;
				return this.http.post<any>(url, signBlrapnDraft, { withCredentials: true });
			}
		},

		create2750ByEdocument: {
			post$: (id: number, changeReason: string, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EDOCUMENT/create2750ByEdocument`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId: id, changeReason }, { withCredentials: true, params });
			}
		},
		saveSigned2750ByEdocument: {
			post$: (signBlrapnDraft: { id: number; signature: number[] }): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EDOCUMENT/saveSigned2750ByEdocument`;
				return this.http.post<any>(url, signBlrapnDraft, { withCredentials: true });
			}
		},

		create2650OnEinvoiceCancel: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EINVOICE/create2650OnEinvoiceCancel`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},
		saveSigned2650OnEinvoiceCancel: {
			post$: (id: number, signature: number[]): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EINVOICE/saveSigned2650OnEinvoiceCancel`;
				return this.http.post<any>(url, { id, signature }, { withCredentials: true });
			}
		},

		create2650OnEwaybillCancel: {
			post$: (documentId: number, isVersion = false, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/create2650OnEwaybillCancel`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		create2650OnEdocumentCancel: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EDOCUMENT/create2650OnEdocumentCancel`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		create2650OnEwaybill: {
			post$: (documentId: number, isVersion = false, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/create2650OnEwaybill`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		create2650OnResponse: {
			post$: (documentId: number, isVersion = false, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/create2650OnResponse`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		create2651OnBlrapn2750: {
			post$: (documentId: number, isVersion = false, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/create2651OnBlrapn2750`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},
		saveSigned2650OnEwaybillCancelAndSendByActiveMq: {
			post$: (id: number, signedXml: number[], isVersion = false): Observable<SignedDraft<EinvoiceDto>> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/saveSigned2650OnEwaybillCancelAndSendByActiveMq`;
				return this.http.post<SignedDraft<EinvoiceDto>>(url, { id, signature: signedXml }, { withCredentials: true });
			}
		},
		saveSigned2650OnEdocumentCancelAndSendByActiveMq: {
			post$: (id: number, signedXml: number[], isVersion = false): Observable<SignedDraft<EinvoiceDto>> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EDOCUMENT/saveSigned2650OnEdocumentCancelAndSendByActiveMq`;
				return this.http.post<any>(url, { id, signature: signedXml }, { withCredentials: true });
			}
		},
		saveSigned2650OnEwaybillAndSendByActiveMq: {
			post$: (id: number, signedXml: number[], isVersion = false): Observable<SignedDraft<EinvoiceDto>> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/saveSigned2650OnEwaybillAndSendByActiveMq`;
				return this.http.post<SignedDraft<EinvoiceDto>>(url, { id, signature: signedXml }, { withCredentials: true });
			}
		},
		create2750ByEwaybill: {
			post$: (blrapnDraft: { documentId: number; changeReason: string }, isVersion = false, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/create2750ByEwaybill`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, blrapnDraft, { withCredentials: true, params });
			}
		},
		saveSigned2750AndSendByActiveMq: {
			post$: (signBlrapnDraft: { id: number; signature: number[] }, isVersion = false): Observable<Ewaybill> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/saveSigned2750AndSendByActiveMq`;
				return this.http.post<Ewaybill>(url, signBlrapnDraft, { withCredentials: true });
			}
		},
		saveSigned2651OnBlrapn2750AndSendByActiveMq: {
			post$: (id: number, signature: number[], isVersion = false): Observable<any> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/saveSigned2651OnBlrapn2750AndSendByActiveMq`;
				return this.http.post<any>(url, { id, signature }, { withCredentials: true });
			}
		},
		saveSigned2650OnResponseAndSendByActiveMq: {
			post$: (id: number, signature: number[], isVersion = false): Observable<any> => {
				const url = `${this.handleApi(isVersion)}/draft/blrapn/EWAYBILL/saveSigned2650OnResponseAndSendByActiveMq`;
				return this.http.post<any>(url, { id, signature }, { withCredentials: true });
			}
		},

		create2650OnEactdif: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/create2650OnEactdif`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		saveSigned2650OnEactdif: {
			post$: (id: number, signedXml: number[]): Observable<SignedDraft<any>> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/saveSigned2650OnEactdif`;
				return this.http.post<SignedDraft<any>>(url, { id, signature: signedXml }, { withCredentials: true });
			}
		},

		create2651OnBlrapn2750Eact: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/create2651OnBlrapn2750`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		saveSigned2651OnBlrapn2750: {
			post$: (id: number, signature: number[]): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/saveSigned2651OnBlrapn2750`;
				return this.http.post<any>(url, { id, signature }, { withCredentials: true });
			}
		},

		create2750OnEactdif: {
			post$: (blrapnDraft: { blrapnDraftId: number; documentId: number; changeReason: string, xml: string }, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/create2750OnEactdif`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, blrapnDraft, { withCredentials: true, params });
			}
		},

		saveSigned2750ByEactdif: {
			post$: (signBlrapnDraft: { id: number; xmlBody: string; signature: number[] }): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/saveSigned2750OnEactdif`;
				return this.http.post<any>(url, signBlrapnDraft, { withCredentials: true });
			}
		},

		create2650OnEactdifCancel: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/create2650OnEactdifCancel`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { documentId }, { withCredentials: true, params });
			}
		},

		saveSigned2650OnEactdifCancel: {
			post$: (id: number, signature: number[]): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EACTDIF/saveSigned2650OnEactdifCancel`;
				return this.http.post<any>(url, { id, signature }, { withCredentials: true });
			}
		},
		/** ROSEU block */
		creatIopForSign: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/ROSEU/${documentId}/iop`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, { }, { withCredentials: true, params });
			}
		},

		getDataForSign: {
			get$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/ROSEU/document/${documentId}/signed`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.get<DraftDto>(url, { withCredentials: true, params });
			}
		},

		saveSignedAndSend: {
			post$: (signDocumentDto: { id: number; xmlBody: string; signature: string | number[], signatureBase64?: string }): Observable<any> => {
				const url = `${this.config.api.root}/ROSEU/document/saveSignedAndSend`;
				return this.http.post<any>(url, signDocumentDto, { withCredentials: true });
			}
		},

		sendSign: {
			post$: (signDocumentDto: { id: number; xmlBody: string; signature: string | number[], signatureBase64?: string }): Observable<any> => {
				const url = `${this.config.api.root}/ROSEU/document/sendSign`;
				return this.http.post<any>(url, signDocumentDto, { withCredentials: true });
			}
		},

		saveSigned: {
			post$: (signDocumentDto: { id: number; xmlBody: string; signature: string | number[]; signatureBase64?: string }): Observable<any> => {
				const url = `${this.config.api.root}/ROSEU/document/saveSigned`;
				return this.http.post<any>(url, signDocumentDto, { withCredentials: true });
			}
		},

		signRoseuDraft: {
			get$: (id: number): Observable<DocumentForSignDto> => {
				const url = `${this.config.api.root}/ROSEU/document/${id}/signed`;
				let params = new HttpParams();
				params = params.set("base64", true.toString());
				return this.http.get<DocumentForSignDto>(url, { withCredentials: true, params });
			}
		}
	};

	public deleteResponse = {
		delete$: (draftIds: number[], docType: DocumentType): Observable<number> => {
			const url = `${this.config.api.root}/${docType}/draft/response/delete`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				body: draftIds,
				withCredentials: true
			};
			return this.http.delete<number>(url, options);
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }

	public delete(draftType: DraftType, draftIds: number[], isVersion = false): Observable<number> {
		const docType = this.getDocType(draftType);
		const url = `${this.handleApi(isVersion)}/${docType}/draft/delete`;
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
			}),
			body: draftIds,
			withCredentials: true
		};
		return this.http.delete<number>(url, options);
	}

	private getDocType(draftType: DraftType): DocumentType {
		switch (draftType) {
			case "DESADV":
				return "DESADV";
			case "BLRWBL" || "BLRDLN" || "BLRDNR" || "BLRWBR":
				return "EWAYBILL";
			case "BLRDLN":
				return "EWAYBILL";
			case "BLRINV":
				return "EINVOICE";
			case "ORDERS":
				return "ORDERS";
			case "BLRPMT":
				return "EINVOICEPMT";
			case "TRANSIT":
				return "TRANSIT";
			case "BLRDOC":
				return "EDOCUMENT";
			case "BLRADF":
				return "EACTDIF";
			case "ECMR":
				return "ECMR";
			case "ROSEU":
				return "ROSEU";
			default:
				throw Error(`Controller for ${draftType} doesn't exist`);
		}
	}

	private handleApi(isVersion: boolean): string {
		return this.config.handleVersionPath(this.config.api.root, isVersion ? undefined : "");
	}
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigurationService } from "@core/configuration.service";
import { DocumentType, ImportReport, ImportResultFromDataMarkFileDto } from "@helper/abstraction/documents";
import { environment } from "src/environments/environment";

export class Import {
	private env = environment;

	constructor(private config: ConfigurationService, private http: HttpClient) { }

	public post$(draftType: DocumentType, file: File, isCopyForFactoring: boolean, isVersion = false): Observable<ImportReport> {
		const url = `${this.config.handleVersionPath(this.config.api.root, isVersion ? undefined : "")}/import/${draftType}/zipWithXml`;
		const formData: FormData = new FormData();
		formData.append("zipArchive", file, file.name);
		let params = new HttpParams();
		if ("EWAYBILL" === draftType && this.env.project === "CTT") {
			params = params.set("isCopyForFactoring", isCopyForFactoring.toString());
		}
		return this.http.post<ImportReport>(url, formData, { withCredentials: true, params });
	}

	public dataMarkFile$(draftType: DocumentType, file: File, isVersion = false, msgType?: string): Observable<ImportResultFromDataMarkFileDto> {
		const url = `${this.config.handleVersionPath(this.config.api.root, isVersion ? undefined : "")}/import/${draftType}/dataMarkFile`;
		const formData: FormData = new FormData();
		formData.append("dataMarkFile", file, file.name);
		let params = new HttpParams();
		if ("EWAYBILL" === draftType && msgType) {
			params = params.set("msgType", msgType.toString());
		}
		return this.http.post<ImportResultFromDataMarkFileDto>(url, formData, { withCredentials: true, params });
	}
}

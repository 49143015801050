import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { BannerDto, BannerStatus } from "@helper/abstraction/banner";
import { Observable } from "rxjs";

export class Banner {
	public actualBanner = {
		get$: (): Observable<BannerDto> => {
			const url = `${this.config.api.root}/banner`;
			return this.http.get<BannerDto>(url, { withCredentials: true });
		}
	};

	public changeBannerStatus = {
		put$: (bannerStatus: BannerStatus): Observable<void> => {
			const url = `${this.config.api.root}/banner`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/json",
				}),
				withCredentials: true
			};
			return this.http.put<void>(url, JSON.stringify(bannerStatus), options);
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

import { Observable } from "rxjs";
import { first, map, switchAll } from "rxjs/operators";

import {
	HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";

import { UserAuthService } from "../services/user-auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class UserAuthInterceptor implements HttpInterceptor {
	constructor(private readonly inj: Injector) { }

	public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const auth = this.inj.get(UserAuthService);

		return auth.token$.pipe(
			first(),
			map(token => {
				if (token) {
					httpRequest = httpRequest.clone({
						headers: httpRequest.headers.set("Authorization", token)
					});
				}
				//strict language assignment
				httpRequest = httpRequest.clone({
					headers: httpRequest.headers.set("Accept-Language", "ru-RU")
				});

				if (["CTT", "BM", "ME"].includes(environment.project) && httpRequest.url.split("/").includes("api") && !httpRequest.url.split("/").includes("cryptomobile")) {
					httpRequest = httpRequest.clone({
						headers: httpRequest.headers.set('X-Integration-Id', environment.id)
													.set('X-Integration-Data', `User-Agent=${window.navigator.userAgent}`)
					});
				}
				return next.handle(httpRequest);
			}),
			switchAll()
		);
	}
}

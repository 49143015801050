import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { StatisticOrderParams, StatisticEwaybillParams, StatisticTransitParams, StatisticEwaybillPartyAccessFilterDto, StatisticEinvoiceParams } from "@helper/abstraction/statistic";
import { PushNotification } from "@helper/abstraction/notifications";
import { UserProfile, UserType } from "@helper/abstraction/user";
import { Organization } from "@helper/abstraction/organization";
import { DocumentType } from "@helper/abstraction/documents";
import { DraftType } from "@helper/abstraction/draft";
import { Role } from "@helper/abstraction/roles";
import { ImportInterface } from "@shared/shared-interfaces";
import { CreateRegistryRq, ExportDocumentXLSXFilteredListDTO } from "./user-core/api/export";
import { ImportTradeItemListDto } from "./marks/marks-store/marks-types";
import { BannerDto, BannerStatus } from "@helper/abstraction/banner";

//#region -------------------ACTIONS OTHER INFORMATION-------------------
export const setToken = createAction(
	"[User] Set Token",
	(payload?: string): { token?: string } => ({ token: payload })
);

export const setRoles = createAction(
	"[User] Set roles",
	(payload: Role[]): { roles: Role[] } => ({ roles: payload })
);

export const getRoles = createAction("[User] Get roles");

export const getRolesSuccess = createAction(
	"[User] Get roles success",
	(payload: Role[]): { roles: Role[] } => ({ roles: payload })
);

export const getRolesError = createAction(
	"[User] Get roles error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const getPermissions = createAction("[User] Get Permissions");

export const getPermissionsSuccess = createAction(
	"[User] Get Permissions Success",
	(payload: string[]): { permissions: string[] } => ({ permissions: payload })
);

export const getPermissionsError = createAction(
	"[User] Get Permissions Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getUserType = createAction(
	"[User] Get User Type",
);

export const getUserTypeSuccess = createAction(
	"[User] Get User Type Success",
	(payload: UserType): { userType: UserType } => ({ userType: payload })
);

export const getUserTypeError = createAction(
	"[User] Get User Type Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getProfile = createAction("[User] Get Profile");
export const getProfileError = createAction(
	"[User] Get Profile Error",
	(payload: Error): { error: Error } => ({ error: payload })
);
export const getProfileSuccess = createAction(
	"[User] Get Profile Success",
	(payload: UserProfile): { userProfile: UserProfile } => ({ userProfile: payload })
);
//#endregion

//#region -------------------ACTIONS NOTIFICATION-------------------
export const resetNotificationCount = createAction(
	"[User] Reset Notification Count",
	(): { notificationCount: undefined } => ({ notificationCount: undefined })
);

export const getNotificationCount = createAction(
	"[User] Get Notification Count",
	(payload: { page: number; size: number, notificationType?: string }): { page: number; size: number, notificationType?: string } => ({ page: payload.page, size: payload.size, notificationType: payload.notificationType })
);

export const getNotificationCountSuccess = createAction(
	"[User] Get Notification Count Success",
	(payload: PushNotification): { notificationCount: PushNotification } => ({ notificationCount: payload })
);

export const getNotificationCountError = createAction(
	"[User] Get Notification Count Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const getOrganization = createAction("[User] Get Organization");
export const getOrganizationError = createAction(
	"[User] Get Organization Error",
	(payload: Error): { error: Error } => ({ error: payload })
);
export const getOrganizationSuccess = createAction(
	"[User] Get Organization Success",
	(payload: Organization): { organizationInfo: Organization } => ({ organizationInfo: payload })
);
//#endregion

//#region -------------------ACTIONS RESTORE USER PASSWORD-------------------
export const restoreUserPassword = createAction(
	"[User] Restore User Password",
	(payload: { email: string; unp: string }): { email: string; unp: string } => ({ email: payload.email, unp: payload.unp })
);

export const restoreUserPasswordSuccess = createAction(
	"[User] Restore User Password Success"
);

export const restoreUserPasswordError = createAction(
	"[User] Restore User Password Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const checkApprovedCode = createAction(
	"[User] Check Approved Code",
	(payload: { code: string; userId: number }): { code: string; userId: number } => ({ code: payload.code, userId: payload.userId })
);

export const checkApprovedCodeSuccess = createAction("[User] Check Approved Code Success");

export const checkApprovedCodeError = createAction(
	"[User] Check Approved Code Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);
//#endregion

//#region -------------------ACTIONS WITH EXPORT XML DOCUMENTS-------------------
export const exportXMLDocuments = createAction(
	"[User] Export XML Documents",
	(payload: { documentType: DraftType; documentIds: number[]; isStatisticValue?: boolean; statisticDocType?: string }, isLimit = false): { params: { documentType: DraftType; documentIds: number[]; isStatisticValue?: boolean; statisticDocType?: string }; isLimit: boolean } => ({ params: payload, isLimit })
);

export const exportXMLDocumentsSuccess = createAction(
	"[User] Export XML Documents Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportXMLDocumentsError = createAction(
	"[User] Export XML Documents Error",
	(payload: HttpErrorResponse | Error, isLimit = false): { error: HttpErrorResponse | Error; isLimit: boolean } => ({ error: payload, isLimit })
);
//#endregion

//#region -------------------ACTIONS WITH EXPORT XLSX Orders-------------------
export const exportXLSXOrders = createAction(
	"[User] Export XLSX Orders",
	(payload: number[]): { documentIds: number[] } => ({ documentIds: payload })
);

export const exportXLSXOrdersSuccess = createAction(
	"[User] Export XLSX Orders Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportXLSXOrdersError = createAction(
	"[User] Export XLSX Orders Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);
//#endregion

//#region -------------------ACTIONS WITH EXPORT XLSX Ewaybill list-------------------
export const exportEwaybillXLSXFilteredList = createAction(
	"[User] Export XLSX Ewaybill list",
	(payload: ExportDocumentXLSXFilteredListDTO, outputType: string): { list: ExportDocumentXLSXFilteredListDTO; outputType: string } => ({ list: payload, outputType })
);

export const exportEwaybillXLSXFilteredListSuccess = createAction(
	"[User] Export XLSX Ewaybill list Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportEwaybillXLSXFilteredListError = createAction(
	"[User] Export XLSX Ewaybill list Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);
//#endregion

//#region -------------------ACTIONS WITH EXPORT XLSX EWAYBILL Emporium Registry-------------------
export const createEmporiumRegistry = createAction(
	"[User] Export XLSX Ewaybill Emporium Registry",
	(payload: number[]): { ewaybillIdx: number[] } => ({ ewaybillIdx: payload })
);

export const createEmporiumRegistrySuccess = createAction(
	"[User] Export XLSX Ewaybill Emporium Registry Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const createEmporiumRegistryError = createAction(
	"[User] Export XLSX Ewaybill Emporium Registry Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);
//#endregion

//#region -------------------ACTIONS WITH EXPORT XLSX DOCUMENTS-------------------
export const exportRegistryBulk = createAction(
	"[Ewaybill] Export Registry Bulk",
	(payload: CreateRegistryRq): { params: CreateRegistryRq } => ({ params: payload })
);

export const exportRegistryBulkSuccess = createAction(
	"[Ewaybill] Export Registry Bulk Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportRegistryBulkError = createAction(
	"[Ewaybill] Export Registry Bulk Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const validationRegistryBulk = createAction(
	"[Ewaybill] Validation Registry Bulk",
	(payload: CreateRegistryRq): { params: CreateRegistryRq } => ({ params: payload })
);

export const validationRegistryBulkSuccess = createAction(
	"[Ewaybill] Validation Registry Bulk Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const validationRegistryBulkError = createAction(
	"[Ewaybill] Validation Registry Bulk Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);
//#endregion

//#region -------------------- ACTIONS EXPORT XLSX ---------------------
export const exportXLSXDocuments = createAction(
	"[User] Export XLSX Documents",
	(payload: { documentType: DocumentType; documentIds: number[]; shortForm?: boolean; outputType?: string; printEds?: boolean }, isMassAction = false): { params: { documentType: DocumentType; documentIds: number[]; shortForm?: boolean; outputType?: string; printEds?: boolean }, isMassAction: boolean } => ({ params: payload, isMassAction })
);

export const exportXLSXDocumentsSuccess = createAction(
	"[User] Export XLSX Documents Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportXLSXDocumentsError = createAction(
	"[User] Export XLSX Documents Error",
	(payload: HttpErrorResponse | Error, isMassAction = false): { error: HttpErrorResponse | Error, isMassAction: boolean } => ({ error: payload, isMassAction })
);

export const exportOrdersWithRelations = createAction(
	"[User] Export Orders With Relations",
	(orderIdList: number[] ): { orderIdList: number[] } => ({ orderIdList })
);

export const exportOrdersWithRelationsSuccess = createAction(
	"[User] Export Orders With Relations Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportOrdersWithRelationsError = createAction(
	"[User] Export Orders With Relations Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);

export const downloadZipArchive = createAction(
	"[User] Download Archive",
	(draftType: DraftType, id: number): { draftType: DraftType, id: number } => ({ draftType, id })
);

export const downloadZipArchiveSuccess = createAction(
	"[User] Download Archive Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const downloadZipArchiveError = createAction(
	"[User] Download Archive Error",
	(payload: HttpErrorResponse | Error): { error: HttpErrorResponse | Error } => ({ error: payload })
);
//#endregion

//#region -------------------ACTIONS WITH CREATE REGISTRY-------------------
export const createRegistry = createAction(
	"[Documents] Create Registry",
	(payload: string): { id: string } => ({ id: payload })
);

export const createRegistrySuccess = createAction(
	"[Documents] Create Registry Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const createRegistryError = createAction(
	"[Documents] Create Registry Error",
	(payload: Error): { error: Error } => ({ error: payload })
);
//#endregion

//#region  -------------------------- ACTIONS WITH STATISTICS XLSX --------------------------
export const getStatisticsOrdersXLSX = createAction(
	"[Statistic] Get Statistics Orders XLSX",
	(payload: StatisticOrderParams): { statisticFilterParams: StatisticOrderParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsTransitXLSX = createAction(
	"[Statistic] Get Statistics Transit XLSX",
	(payload: StatisticTransitParams): { statisticFilterParams: StatisticTransitParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsEwaybillXLSX = createAction(
	"[Statistic] Get Statistics Ewaybill XLSX",
	(payload: StatisticEwaybillParams): { statisticFilterParams: StatisticEwaybillParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsEinvoiceXLSX = createAction(
	"[Statistic] Get Statistics Einvoice XLSX",
	(payload: StatisticEinvoiceParams): { statisticFilterParams: StatisticEinvoiceParams } => ({ statisticFilterParams: payload })
);

export const getStatisticsEwaybillTPAXLSX = createAction(
	"[Statistic] Get Statistics Ewaybill ThirdPartyAccess XLSX",
	(payload: StatisticEwaybillPartyAccessFilterDto): { statisticFilterParams: StatisticEwaybillPartyAccessFilterDto } => ({ statisticFilterParams: payload })
);

export const getStatisticsXLSXError = createAction(
	"[Statistic] Get Statistics XLSX Error",
	(payload: Error): { statisticError: Error } => ({ statisticError: payload })
);

export const getStatisticsXLSXSuccess = createAction(
	"[Statistic] Get Statistics XLSX Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

//#endregion

//#region import and mass actions & report actions
export const setImportObject = createAction(
	"[User] Set Import Object",
	(importObject: ImportInterface): { importObject: ImportInterface } => ({ importObject })
);

export const resetImportObject = createAction("[User] Reset Import Object");


export const changeHintReportStatus = createAction(
	"[User] Change Hint Report Status",
	(hintReport: boolean): { hintReport: boolean } => ({ hintReport })
);

export const importGoodsDocument = createAction(
	"[User] Import Goods Document",
	(file: File): { file: File } => ({ file })
);

export const importGoodsDocumentSuccess = createAction(
	"[User] Import Goods Document Success",
	(report: ImportTradeItemListDto): { report: ImportTradeItemListDto } => ({ report })
);

export const importGoodsDocumentError = createAction(
	"[User] Import Goods Document Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

//#endregion import and mass actions & report actions

//#region -------------------ACTIONS CHECK DOCUMENT-------------------
export const checkDocument = createAction(
	"[User] Check Document",
	(documentParams: any): { documentParams: any } => ({ documentParams })
);

export const checkDocumentSuccess = createAction(
	"[User] Check Document Success",
	(document: any): { document: any } => ({ document })
);

export const checkDocumentError = createAction(
	"[User] Check Document Error",
	(errorResponse: HttpErrorResponse): { errorResponse: HttpErrorResponse } => ({ errorResponse })
);
//#endregion
export const saveDocument = createAction(
	"[User] Save Document To Store",
	(document: any): { document: any } => ({ document })
);

export const clearDocument = createAction(
	"[User] Clear Document To Store"
);

export const getCertificates = createAction(
	"[User] Get Certificates"
);

export const getCertificatesSuccess = createAction(
	"[User] Get Certificates Success",
	(response: any): { response: any } => ({ response })
);

export const getCertificatesError = createAction(
	"[User] Get Certificates Error",
	(errorResponse: HttpErrorResponse): { errorResponse: HttpErrorResponse } => ({ errorResponse })
);

export const getBannerStatus = createAction(
	"[User] Get Banner Status"
);

export const getBannerStatusSuccess = createAction(
	"[User] Get Banner Status Success",
	(banner: BannerDto): { banner: BannerDto } => ({ banner })
);

export const getBannerStatusError = createAction(
	"[User] Get Banner Status Error",
	(error: any): { error: any } => ({ error })
);

export const resetBanner = createAction(
	"[User] Reset Banner"
);

export const changeBannerStatus = createAction(
	"[User] Change Banner Status",
	(bannerStatus : BannerStatus, isFinal: boolean): { bannerStatus: BannerStatus, isFinal: boolean } => ({ bannerStatus, isFinal })
);

export const changeBannerStatusSuccess = createAction(
	"[User] Change Banner Status Success",
	(isFinal: boolean): { isFinal: boolean } => ({ isFinal })
);

export const changeBannerStatusError = createAction(
	"[User] Change Banner Status Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

export const saveUiData = createAction(
	"[User] Save UiData",
	(data : string): { data: string } => ({ data })
);

export const saveUiDataSuccess = createAction(
	"[User] Save UiData Succress"
);

export const saveUiDataError = createAction(
	"[User] Save UiData Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

export const exportDts = createAction(
	"[User] Export Dts",
	(payload: { documentType: DocumentType; documentId: number; signatureId: number }): { params: { documentType: DocumentType; documentId: number; signatureId: number } } => ({ params: payload })
);

export const exportDtsSuccess = createAction(
	"[User] Export Dts Success",
	(payload: { data: Blob; name: string; extension: string }): { file: { data: Blob; name: string; extension: string } } => ({ file: payload })
);

export const exportDtsError = createAction(
	"[User] Export Dts Error",
	(payload: HttpErrorResponse | Error, isMassAction = false): { error: HttpErrorResponse | Error, isMassAction: boolean } => ({ error: payload, isMassAction })
);

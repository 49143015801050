import { Observable } from "rxjs";
import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Invitation, InvitationDto, InvitationParams } from "@helper/abstraction/invitation";
import { PartyDto } from "@helper/abstraction/mns-ewaybill";
import { RoamingRequestDataDto, RoamingRequestDto, RoamingRequestListFilterDto, VerifiedRoamingRequestDto } from "@helper/abstraction/counterparty";
import { Status } from "@helper/abstraction/status";

export class CounterParty {
	public get apiUrl(): string { return `${this.config.api.party}/invitation`; }
	public get roamingUrl(): string { return `${this.config.api.roaming}`; }

	public readonly invitation = {
		get$: (invParams: InvitationParams): Observable<InvitationDto[]> => {
			const url = this.apiUrl;
			let params = new HttpParams();
			params = params.set("page", invParams.page?.toString() || "1");
			params = params.set("size", invParams.size?.toString() || "50");
			if (invParams.unp) {
				params = params.set("unp", invParams.unp);
			}
			if (invParams.statuses?.length) {
				params = params.set("statuses", invParams.statuses.toString());
			}
			return this.http.get<InvitationDto[]>(url, { withCredentials: true, params });
		},

		post$: (invitation: Invitation[]): Observable<InvitationDto[]> => {
			const url = this.apiUrl;
			return this.http.post<InvitationDto[]>(url, invitation, { withCredentials: true });
		},

		finalizeById$: (ids?: number[]): Observable<void> => {
			const url = `${this.apiUrl}/finalizeById`;
			return this.http.post<void>(url, ids, { withCredentials: true });
		},

		put$: (id: number, invitation: Invitation): Observable<InvitationDto[]> => {
			const url = `${this.apiUrl}/${id}`;
			return this.http.put<InvitationDto[]>(url, invitation, { withCredentials: true });
		},

		delete$: (ids: number[]): Observable<void> => {
			const url = this.apiUrl;
			const options = {
				body: ids,
				withCredentials: true
			};
			return this.http.delete<void>(url, options);
		}
	};

	public readonly roaming = {
		filteredList$: (roamingRequest: RoamingRequestListFilterDto): Observable<RoamingRequestDataDto> => {
			let params = new HttpParams();
			params = params.set("page", roamingRequest.page?.toString() || "1");
			params = params.set("size", roamingRequest.size?.toString() || "50");
			const url = `${this.roamingUrl}filteredList`;
			return this.http.post<RoamingRequestDataDto>(url, roamingRequest, { params, withCredentials: true });
		},

		directionsList$: (): Observable<Status[]> => {
			const url = `${this.roamingUrl}direction/list`;
			return this.http.get<Status[]>(url, { withCredentials: true });
		},

		list$: (): Observable<PartyDto[]> => {
			const url = `${this.roamingUrl}providers/list`;
			return this.http.get<PartyDto[]>(url, { withCredentials: true });
		},

		saveAndSend$: (body: VerifiedRoamingRequestDto): Observable<void> => {
			const url = `${this.roamingUrl}saveAndSend`;
			return this.http.post<void>(url, body, { withCredentials: true });
		},

		verify$: (body: RoamingRequestDto): Observable<VerifiedRoamingRequestDto> => {
			const url = `${this.roamingUrl}verify`;
			return this.http.post<VerifiedRoamingRequestDto>(url, body, { withCredentials: true });
		}
	};

	constructor(
		private config: ConfigurationService,
		private http: HttpClient
	) {
	}
}

import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { EinvoicepmtDto as IEinvoicepmt } from "@helper/abstraction/einvoicepmt";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs";
import { DraftDto } from "@helper/abstraction/draft";

export class Einvoicepmt extends DocumentController<IEinvoicepmt> {
	public get apiUrl(): string { return `${this.config.api.einvoicepmt}`; }

	public blrapn = {
		create2650ByEinvoicepmt: {
			post$: (documentId: number, base64?: boolean): Observable<DraftDto> => {
				const url = `${this.config.api.root}/draft/blrapn/EINVOICEPMT/create2650ByEinvoicepmt`;
				let params = new HttpParams();
				if (typeof base64 === "boolean") {
					params = params.set("base64", base64!.toString());
				}
				return this.http.post<DraftDto>(url, documentId, { withCredentials: true, params });
			}
		},
		saveSigned2650ByEinvoicepmt: {
			post$: (id: number, signedXml: number[]): Observable<any> => {
				const url = `${this.config.api.root}/draft/blrapn/EINVOICEPMT/saveSigned2650ByEinvoicepmt`;
				return this.http.post<any>(url, { id, signature: signedXml }, { withCredentials: true });
			}
		},
	};

	public checkSign = {
		get$: (documentId: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${documentId}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}

import { Status } from "./../../../helper/abstraction/status";
import { createAction } from "@ngrx/store";
import {
	ManagementList,
	ManagementListParams,
	ManagementUserDto
} from "@helper/abstraction/management";
import { HttpErrorResponse } from "@angular/common/http";

export const getManagementList = createAction(
	"[Customization] Get Users List",
	(payload: ManagementListParams): { params: ManagementListParams } => ({ params: payload })
);

export const getManagementListSuccess = createAction(
	"[Customization] Get Users List Success",
	(payload: ManagementList): { list: ManagementList } => ({ list: payload })
);

export const resetManagementList = createAction(
	"[Customization] Reset Users List"
);

export const getManagementListError = createAction(
	"[Customization] Get Users List Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getStatusesList = createAction(
	"[Customization] Get Users Statuses List"
);

export const getStatusesListSuccess = createAction(
	"[Customization] Get Users Statuses List Success",
	(payload: Status[]): { list: Status[] } => ({ list: payload })
);

export const getStatusesListError = createAction(
	"[Customization] Get Users Statuses List Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const updateManagementFilter = createAction(
	"[Customization] Update Management Filter",
	(payload: ManagementListParams): { filter: ManagementListParams } => ({ filter: payload })
);

export const addUser = createAction(
	"[Customization] Management Create User",
	(payload: ManagementUserDto): { user: ManagementUserDto } => ({ user: payload })
);

export const addUserSuccess = createAction(
	"[Customization] Management Create User Success"
);

export const addUserError = createAction(
	"[Customization] Management Create User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const getUser = createAction(
	"[Customization] Get User",
	(payload: number): { userId: number } => ({ userId: payload })
);

export const getUserSuccess = createAction(
	"[Customization] Get User Success",
	(payload: ManagementUserDto): { user: ManagementUserDto } => ({ user: payload })
);

export const getUserError = createAction(
	"[Customization] Get User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const editUser = createAction(
	"[Customization] Management Edit User",
	(payload: ManagementUserDto): { user: ManagementUserDto } => ({ user: payload })
);

export const editUserSuccess = createAction(
	"[Customization] Management Edit User Success"
);

export const editUserError = createAction(
	"[Customization] Management Edit User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const blockUsers = createAction(
	"[Customization] Management Block User",
	(payload: number[]): { blockIds: number[] } => ({ blockIds: payload })
);

export const blockUsersSuccess = createAction(
	"[Customization] Management Block User Success",
	(payload: number[]): { blockIds: number[] } => ({ blockIds: payload })
);

export const blockUsersError = createAction(
	"[Customization] Management Block User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const unblockUsers = createAction(
	"[Customization] Management Unblock User",
	(payload: number[]): { unblockIds: number[] } => ({ unblockIds: payload })
);

export const unblockUsersSuccess = createAction(
	"[Customization] Management Unblock User Success",
	(payload: number[]): { unblockIds: number[] } => ({ unblockIds: payload })
);

export const unblockUsersError = createAction(
	"[Customization] Management Unblock User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const deleteUsers = createAction(
	"[Customization] Delete Users",
	(payload: number[]): { deleteIds: number[] } => ({ deleteIds: payload })
);

export const deleteUsersSuccess = createAction(
	"[Customization] Delete Users Success",
	(payload: number[]): { deleteIds: number[] } => ({ deleteIds: payload })
);

export const deleteUsersError = createAction(
	"[Customization] Management Unblock User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const restoreUsers = createAction(
	"[Customization] Restore Users",
	(payload: number[]): { restoreIds: number[] } => ({ restoreIds: payload })
);

export const restoreUsersSuccess = createAction(
	"[Customization] Restore Users Success",
	(payload: number[]): { restoreIds: number[] } => ({ restoreIds: payload })
);

export const restoreUsersError = createAction(
	"[Customization] Restore User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

import { ConfigurationService } from "@core/configuration.service";
import { HttpClient } from "@angular/common/http";
import { ShipmentNotification } from "@helper/abstraction/shipment-notification";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs/internal/Observable";
import { DocumentFromAnotherParams } from "@helper/abstraction/documents";
import { Ewaybill } from "@helper/abstraction/ewaybill";

export class Desadv extends DocumentController<ShipmentNotification> {
	public get apiUrl(): string { return `${this.config.api.desadv}`; }

	public convertToDraft = {
		EWAYBILL: {
			post$: (desadvId: number, documentFromAnotherParams: DocumentFromAnotherParams, isVersion = false): Observable<Ewaybill> => {
				const url = `${this.config.handleVersionPath(this.apiUrl, isVersion ? undefined : "")}/${desadvId}/convertToDraftEwaybill`;
				delete documentFromAnotherParams.id;
				delete documentFromAnotherParams.sourceDocumentType;
				return this.http.post<Ewaybill>(url, documentFromAnotherParams, { withCredentials: true });
			}
		}
	};

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}

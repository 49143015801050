
<div class="container">
	<header>
		<h3 class="text"> Печатная форма </h3>
		<app-icon (click)="close()">close</app-icon>
	</header>

	<main [formGroup]="form">
		<section class="format-choice">
			<label i18n>Выберите формат:</label>
			<!-- <app-radio-button-list formControlName="outputType" [data]="formats"></app-radio-button-list> -->
			<app-tab formControlName="outputType" [tabs]="outputTypeValues" [newStyle]="true"></app-tab>
		</section>

		<!-- <div class="delimiter"></div> -->

		<section class="additions-checks">
			<div class="row" *ngIf="(documentType === 'EWAYBILL' && formType !== 2) || documentType === 'EACTDIF'" (click)="toggleControlValue('shortForm')">
				<app-icon>
					{{ form.controls.shortForm.value ? 'check_box' : 'check_box_outline_blank' }}
				</app-icon>
				<label>Печатать дополнительные сведения</label>
			</div>

			<div class="row" *ngIf="accessToPrintEds" (click)="toggleControlValue('printEds')">
				<app-icon>
					{{ form.controls.printEds.value ? 'check_box' : 'check_box_outline_blank' }}
				</app-icon>
				<label>{{ "Печать " + (env.project === 'CTT' && ['EINVOICE', 'EWAYBILL', 'EACTDIF'].includes(documentType) ? "штампа" : "") + " ЭЦП" }}</label>
			</div>
		</section>

	</main>

	<footer>
		<button class="save" appSkin="action-save bold" (click)="printData()" i18n>Печать</button>
		<button i18n type="button" appSkin="simple no-shadow bold bordered" (click)="close()">Отмена</button>
	</footer>
</div>
<!-- <template #formats>
	<dl> -->
		<!-- <dt>DOCX</dt>
		<dd>DOCX</dd> -->
		<!-- <dt>PDF</dt>
		<dd>PDF</dd> -->
		<!-- <dt>RTF</dt>
		<dd>RTF</dd> -->
		<!-- <dt>XLSX</dt>
		<dd>XLSX</dd>
	</dl>
</template> -->

import { Injectable, NgZone, OnDestroy } from "@angular/core";
import { ConfigurationService } from "@core/configuration.service";
import { Observable, Subject } from "rxjs";

const TOKEN_KEY = "token.user.v1";
@Injectable({
	providedIn: "root"
})
export class SseService implements OnDestroy {
	public eventSource?: EventSource;
	private readonly unsubscribe$ = new Subject<void>();

	constructor(
		private config: ConfigurationService,
		private zone: NgZone,
	) {
		window.onbeforeunload = (): void => this.ngOnDestroy();
	}
	public getServerSentEvent(): Observable<MessageEvent> {
		return new Observable<MessageEvent>((observer) => {
			const token = localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
			const url = `${this.config.api.root}/notification/sse?uuid=${token}`;
			if (token) {
				if (this.eventSource) this.eventSource.close();
				this.eventSource = new EventSource(url);
				this.eventSource.addEventListener("notification", (event: any) =>
					this.zone.run(() => observer.next(event)));
			}
		});
	}

	public ngOnDestroy(): void {
		if (this.eventSource) this.eventSource.close();
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}

import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { environment } from "../../../../environments/environment";

@Injectable()
export class BMEnvironmentGuard implements CanActivate {

	constructor(private router: Router) { }

	public canActivate(): boolean {
		if (environment.project !== "BM") {
			this.router.navigate(["user/login"]);
			return false;
		} else
			return true;
	}
}

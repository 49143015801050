<section>
	<header>
		<label i18n>Введите GLN</label>
		<app-icon (click)="close()">close</app-icon>
	</header>
	<form [formGroup]="form">
		<div>
			<label i18n>GLN:</label>
			<app-numberbox formControlName="gln" maxLength="13" name="GLN склада"></app-numberbox>
		</div>
	</form>
	<footer>
		<button type="button" appSkin="action-save" (click)="add()">Добавить</button>
		<button appSkin="simple-cancel" (click)="close()">Закрыть</button>
	</footer>
</section>

<app-overlay #notification></app-overlay>
<app-pending *ngIf="pending$ | async"></app-pending>
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import {
	StatisticEwaybillFormValue,
	StatisticTransitFormValue,
	StatisticOrdersFormValue,
	StatisticEinvoiceFormValue,
	StatisticEDocumentFormValue
} from "@helper/abstraction/statistic";

export type CookieServiceType =
	StatisticEwaybillFormValue |
	StatisticTransitFormValue |
	StatisticOrdersFormValue |
	StatisticEinvoiceFormValue |
	StatisticEDocumentFormValue |
	any | undefined;

@Injectable()
export class UserCookieService {
	private cookieValueName = "";
	constructor(
		private readonly cookieService: CookieService,
	) { }

	public setCookieName(name: string): void {
		this.cookieValueName = name;
	}
	public clearCookieName(): void {
		this.cookieValueName = "";
	}

	public setCookieFilterValue(filterValue: any): void {
		this.cookieService.set(this.cookieValueName, JSON.stringify(filterValue), undefined, "/");
	}

	public getCookieFilterValue(): any {
		return JSON.parse(this.cookieService.get(this.cookieValueName));
	}

	public clearCookieFilterValue(): void {
		this.cookieService.delete(this.cookieValueName, "/");
	}

	public isCookieFilterValueExist(name?: string): boolean {
		return this.cookieService.check(name || this.cookieValueName);
	}

	public clearCookies(): void {
		return this.cookieService.deleteAll();
	}
}

import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { NewInboxDocumentsCountDto } from "@helper/abstraction/documents";
import {
	StatisticEwaybillParams,
	StatisticEwaybillDto,
	StatisticRequestFilterParams,
	ShipperDto,
	DeliveryPlaceFilter,
	DeliveryPointStatistic,
	StatisticOrderParams,
	StatisticOrdersDto,
	StatisticTabs, StatisticTransitDto, StatisticTransitParams, StatisticEinvoiceDto, StatisticEinvoiceParams, StatisticEwaybillPartyAccessDto, StatisticEwaybillPartyAccessParams, PartyDto, EwaybillThirdPartyIdDto
} from "@helper/abstraction/statistic";

export class Statistic {

	public messagesCount = {
		get$: (): Observable<NewInboxDocumentsCountDto> => {
			const url = `${this.config.api.root}/statistic/messagesCount`;

			return this.http.get<NewInboxDocumentsCountDto>(url, { withCredentials: true });
		}
	};

	public findEwaybillByFilter = {
		post$: (statisticParams: StatisticEwaybillParams): Observable<StatisticEwaybillDto> => {
			const url = `${this.config.api.root}/statistic/findEwaybillByFilter`;
			const { page, size, ...filterDto } = statisticParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			if (size) params = params.set("size", size.toString());
			return this.http.post<StatisticEwaybillDto>(url, filterDto, { params, withCredentials: true });
		}
	};

	public findEwaybillThirdPartyAccessStatisticByFilter = {
		post$: (statisticParams: StatisticEwaybillPartyAccessParams): Observable<StatisticEwaybillPartyAccessDto> => {
			const url = `${this.config.api.root}/statistic/findEwaybillThirdPartyAccessStatisticByFilter`;
			const transferStatus =  statisticParams?.transferStatus?.map(st => st?.id);
			const { page, size } = statisticParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			if (size) params = params.set("size", size.toString());
			return this.http.post<StatisticEwaybillPartyAccessDto>(url, { ...statisticParams, transferStatus }, { params, withCredentials: true });
		}
	};

	public findOrdersStatisticByFilter = {
		post$: (statisticOrdersParams: StatisticOrderParams): Observable<StatisticOrdersDto> => {
			const url = `${this.config.api.root}/statistic/findOrdersStatisticByFilter`;
			const { page, size, ...filterDto } = statisticOrdersParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			if (size) params = params.set("size", size.toString());
			return this.http.post<StatisticOrdersDto>(url, filterDto, { params, withCredentials: true });
		}
	};

	public findTransitByFilter = {
		post$: (statisticOrdersParams: StatisticTransitParams): Observable<StatisticTransitDto> => {
			const url = `${this.config.api.root}/statistic/findTransitByFilter`;
			const { page, size, ...filterDto } = statisticOrdersParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			if (size) params = params.set("size", size.toString());
			return this.http.post<StatisticTransitDto>(url, filterDto, { params, withCredentials: true });
		}
	};

	public findEinvoiceByFilter = {
		post$: (statisticOrdersParams: StatisticEinvoiceParams): Observable<StatisticEinvoiceDto> => {
			const url = `${this.config.api.root}/statistic/findEinvoiceByFilter`;
			const { page, size, ...filterDto } = statisticOrdersParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			if (size) params = params.set("size", size.toString());

			const filterData = Object.assign(filterDto, {}) as any;
			if (filterData.invoiceDatesType === "0") {
				delete filterData.startInvoiceDate; 
				delete filterData.endInvoiceDate;
			} else {
				delete filterData.startDate; 
				delete filterData.endDate;
			}
			delete filterData.invoiceDatesType;
			return this.http.post<StatisticEinvoiceDto>(url, filterData, { params, withCredentials: true });
		}
	};

	public findDocumentByFilter = {
		post$: (statisticEdocumentParams: any): Observable<any> => {
			const url = `${this.config.api.root}/statistic/findDocumentByFilter`;
			const { page, size, ...filterDto } = statisticEdocumentParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<any>(url, filterDto, { params, withCredentials: true });
		}
	};

	public valuesStatistic = {
		post$: (statisticRequestFilterParams: StatisticRequestFilterParams): Observable<ShipperDto> => {
			const url = `${this.config.api.root}/statistic/valuesStatistic`;
			statisticRequestFilterParams = {
				...statisticRequestFilterParams,
				keyWord: statisticRequestFilterParams.searchText || ""
			};
			const { page, size, ...statisticValuesDto } = statisticRequestFilterParams;
			let params = new HttpParams();
			params = params.set("page", page.toString());
			params = params.set("size", size.toString());
			return this.http.post<ShipperDto>(url, statisticValuesDto, { params, withCredentials: true });
		}
	};

	public partnersAndSuppliers = {
		post$: (partyName: string): Observable<PartyDto> => {
			let qP = "";
			if (partyName || partyName !== "") { qP = "?partyName=" + partyName; }
			const url = `${this.config.api.root}/organization/partnersAndSuppliers/list${qP}`;
			return this.http.post<PartyDto>(url, { withCredentials: true });
		}
	};

	public findActiveStorageAndNsiGeneralGlnByCondition = {
		get$: (deliveryPlaceFilter: DeliveryPlaceFilter): Observable<DeliveryPointStatistic[]> => {
			const url = `${this.config.api.root}/statistic/findActiveStorageAndNsiGeneralGlnByCondition`;
			let params = new HttpParams();
			params = params.set("page", deliveryPlaceFilter.page.toString());
			params = params.set("size", deliveryPlaceFilter.size.toString());
			if (deliveryPlaceFilter.relatedPartyId.length) {
				params = params.set("relatedPartyId", deliveryPlaceFilter.relatedPartyId.toString());
			}
			if (deliveryPlaceFilter.storageAddressFull) {
				params = params.set("storageAddressFull", deliveryPlaceFilter.storageAddressFull);
			}
			if (deliveryPlaceFilter.storageGln) {
				params = params.set("storageGln", deliveryPlaceFilter.storageGln);
			}
			return this.http.get<DeliveryPointStatistic[]>(url, { params, withCredentials: true });
		}
	};

	public tabs = {
		get$: (): Observable<StatisticTabs> => {
			const url = `${this.config.api.root}/statistic/items`;
			return this.http.get<StatisticTabs>(url, { withCredentials: true });
		}
	};

	public thirdPartyAccessActionLoading = {
		post$: (action: string, params: EwaybillThirdPartyIdDto[]): Observable<EwaybillThirdPartyIdDto> => {
			const url = `${this.config.api.root}/thirdPartyAccess/EWAYBILL/${action}`;
			return this.http.post<EwaybillThirdPartyIdDto>(url, params, { withCredentials: true });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { DeliveryPlaceFilter, DeliveryPointStatistic, StatisticEwaybillPartyAccessFilterDto, StatisticOrderParams } from "@helper/abstraction/statistic";
import { DocumentState, DocumentType, MessageType } from "@helper/abstraction/documents";
import { Status } from "@helper/abstraction/status";
import { ConfigurationService } from "@core/configuration.service";
import { DraftType } from "@helper/abstraction/draft";

export interface ResponseEntity {
	body: any,
	statusCode: string,
	statusCodeValue: number,
}

export interface RequestHtmlModelParams {
	id: number,
	code: string,
	msgType: DocumentType,
	outputType: "HTML" | "PDF"
}

export interface CreateRegistryRq {
	id?: number;
	ids?: (number | undefined)[];
	registryParametersDto?: RegistryParametersDto;
}

export interface ExportDocumentXLSXFilteredListDTO {
	documentListFilterDto?: {
		dateUpdateFrom?: Date;
		dateUpdateTo?: Date;
		documentDateEnd?: Date;
		documentDateStart?: Date;
		documentNumber?: string;
		documentType?: MessageType[];
		partnerId?: string;
		processingStatusList?: Status[];
		receiverId?: number;
		senderId?: number;
		storageId?: string;
	};
	documentState?: DocumentState | string;
	draftIds?: number[];
	ids?: number[];
	responseDraftIds?: number[];
}

export interface RegistryParametersDto {
	deliveryContact: string;
	freightPayerAddress: string;
	freightPayerName: string;
	freightPayerUnp: string;
	shipperContact: string;
	trailerNumber: string;
	transportContact: string;
	transportNumber: string;
	waybillNumber: string;
	documentDate?: string;
	documentNumber?: string;
	baseShippingDocumentNumber?: string;
	baseShippingDocumentName?: string;
}

export class Export {
	public createFormatDocument = {
		post$: (id: number[], msgTypeForGenerator: DocumentType, shortForm = false, exportFormatType = "XLSX", printEds = false): Observable<HttpResponse<Blob>> => {
			let params = new HttpParams();
			params = params.set("shortForm", `${shortForm}`);
			params = params.set("exportFormatType", `${exportFormatType}`);
			params = params.set("printEds", `${printEds}`);
			const url = `${this.config.api.root}/export/createFormatDocuments`;
			return this.http.post<Blob>(url, { id, msgTypeForGenerator }, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response",
				params
			});
		}
	};

	public ordersWithRelations = {
		post$: (orderIdList: number[]): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/zip/ordersWithRelations`;
			return this.http.post<Blob>(url, orderIdList, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public createRegistry = {
		post$: (id: string): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/createRegistry`;
			return this.http.post<Blob>(url, { id }, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public createRegistryBulk = {
		post$: (body: CreateRegistryRq): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/createRegistry/bulk`;
			return this.http.post<Blob>(url, body, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public validationRegistryBulk = {
		post$: (body: CreateRegistryRq): Observable<any> => {
			const url = `${this.config.api.root}/export/createRegistry/validation`;
			return this.http.post(url, body, { withCredentials: true });
		}
	};

	public ewaybillList = {
		post$: (statisticEwaybillParams: any, outputType: "PDF" | "RTF" | "XLS" | "DOCX" | "XLSX" | "XML"): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/ewaybillList/${outputType}`;
			delete statisticEwaybillParams.page;
			delete statisticEwaybillParams.size;
			return this.http.post<Blob>(url, statisticEwaybillParams, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public einvoiceList = {
		post$: (statisticEinvoiceParams: any, outputType: "PDF" | "RTF" | "XLS" | "DOCX" | "XLSX" | "XML"): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/einvoiceList/${outputType}`;
			delete statisticEinvoiceParams.page;
			delete statisticEinvoiceParams.size;
			const filterData = Object.assign(statisticEinvoiceParams, {}) as any;
			if (filterData.invoiceDatesType === "0") {
				delete filterData.startInvoiceDate; 
				delete filterData.endInvoiceDate;
			} else {
				delete filterData.startDate; 
				delete filterData.endDate;
			}
			delete filterData.invoiceDatesType;
			return this.http.post<Blob>(url, filterData, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public ewaybillTPAList = {
		post$: (statisticEwaybillTPAParams: StatisticEwaybillPartyAccessFilterDto): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/createThirdPartyAccessStatistic/XLSX`;
			return this.http.post<Blob>(url, statisticEwaybillTPAParams, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public storageAndNsiGeneralGln = {
		findAllActiveByCondition: {
			get$: (deliveryPlaceFilter: DeliveryPlaceFilter): Observable<DeliveryPointStatistic[]> => {
				const url = `${this.config.api.root}/storageAndNsiGeneralGln/findAllActiveByCondition`;
				let params = new HttpParams();
				params = params.set("page", deliveryPlaceFilter.page.toString());
				params = params.set("size", deliveryPlaceFilter.size.toString());
				if (deliveryPlaceFilter.relatedPartyId.length) {
					params = params.set("relatedPartyId", deliveryPlaceFilter.relatedPartyId.toString());
				}
				if (deliveryPlaceFilter.storageAddressFull) {
					params = params.set("storageAddressFull", deliveryPlaceFilter.storageAddressFull);
				}
				if (deliveryPlaceFilter.storageGln) {
					params = params.set("storageGln", deliveryPlaceFilter.storageGln);
				}
				return this.http.get<DeliveryPointStatistic[]>(url, { params, withCredentials: true });
			}
		}
	};

	public statisticOrdersWithRelations = {
		post$: (statisticOrderParams: any): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/statisticOrdersWithRelations`;
			delete statisticOrderParams.page;
			delete statisticOrderParams.size;
			return this.http.post<Blob>(url, statisticOrderParams, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public documentListExport = {
		post$: (statisticOrderParams: StatisticOrderParams, outputType: "PDF" | "RTF" | "XLS" | "DOCX" | "XLSX" | "XML" | "ODS"): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/documentList/${outputType}`;
			return this.http.post<Blob>(url, statisticOrderParams, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public createUnfinishedDocumentsReport = {
		post$: (): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/createUnfinishedDocumentsReport/XLSX`;
			return this.http.post<Blob>(url, null, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public createEmporiumRegistry = {
		post$: (ewaybillIdx: number[]): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/createEmporiumRegistry/XLSX`;
			return this.http.post<Blob>(url, ewaybillIdx, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public ewaybillExportFilteredList = {
		post$: (filterDto: ExportDocumentXLSXFilteredListDTO, outputType: string): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/ewaybillFilteredList/${outputType}`;
			return this.http.post<Blob>(url, filterDto, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public statisticTransitWithRelations = {
		post$: (statisticTransitParams: any, outputType: string): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/transitList/${outputType}`;
			delete statisticTransitParams.page;
			delete statisticTransitParams.size;
			return this.http.post<Blob>(url, statisticTransitParams, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public zip = {
		ordersWithRelations: {
			post$: (ids: number[]): Observable<HttpResponse<Blob>> => {
				const url = `${this.config.api.root}/export/zip/ordersWithRelations`;
				return this.http.post<Blob>(url, ids, {
					withCredentials: true,
					responseType: "blob" as "json",
					observe: "response"
				});
			}
		},

		archive: {
			post$: (documentType: DraftType, id: number): Observable<HttpResponse<Blob>> => {
				const url = `${this.config.api.root}/${documentType}/downloadArchive`;
				return this.http.post<Blob>(url, id, {
					withCredentials: true,
					responseType: "blob" as "json",
					observe: "response"
				});
			}
		}
	};

	public cryptoProxy = {
		post$: (): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/cryptoProxy`;
			return this.http.post<Blob>(url, {}, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public base64CryptoProxy = {
		post$: (): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/export/base64CryptoProxy`;
			return this.http.post<Blob>(url, {}, {
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	public ewaybillHtml = {
		post$: (body: { id: number[], msgTypeForGenerator: string }): Observable<ResponseEntity> => {
			const url = `${this.config.api.root}/export/ewaybillHtml`;
			return this.http.post<ResponseEntity>(url, body, { withCredentials: true });
		}
	};

	public getDocumentPrintByCode = {
		get$: (pathParams: RequestHtmlModelParams): Observable<any> => {
			const { msgType, id, outputType, code } = pathParams;
			const url = `${this.config.api.root}/export/${msgType}/${id}/${outputType}`;
			
			let params = new HttpParams();
			params = params.set("code", code.toString());
			return this.http.get<any>(url, { params });
		}
	};

	public exportDts = {
		get$: (documentType: DocumentType, documentId: number, signatureId: number): Observable<HttpResponse<Blob>> => {
			const url = `${this.config.api.root}/${documentType}/${documentType === "EWAYBILL" ? "" : "document/"}${documentId}/signature/${signatureId}/receipt`;
			// TODO change mns api
			// const url = `${this.config.api.root}/${documentType}/document/${documentId}/signature/${signatureId}/receipt`;
			return this.http.get<Blob>(url, {
				headers: new HttpHeaders({ "Content-Type": "application/json" }),
				withCredentials: true,
				responseType: "blob" as "json",
				observe: "response"
			});
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

import { Observable } from "rxjs";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { AuthenticateResponse, AuthenticateToken, LoginByCertificate } from "@helper/abstraction/authenticate";
import { Role } from "@helper/abstraction/roles";
import { UserType } from "@helper/abstraction/user";

export class Authentication {
	public authenticate = {
		post$: (username: string, password: string): Observable<AuthenticateResponse> => {
			const url = `${this.config.api.root}/authentication/authenticate`;
			return this.http.post<AuthenticateResponse>(url, { username, password });
		}
	};

	public govLogin = {
		post$: (username: string, password: string): Observable<AuthenticateResponse> => {
			const url = `${this.config.api.gov}/login`;
			return this.http.post<AuthenticateResponse>(url, { username, password });
		}
	};

	public authByCertificate = {
		post$: (data: LoginByCertificate): Observable<AuthenticateResponse> => {
			const url = `${this.config.api.root}/authentication/authByCertificate`;
			return this.http.post<AuthenticateResponse>(url, data);
		}
	};

	public authToken = {
		get$: (publicKeyId: string): Observable<AuthenticateToken> => {
			const url = `${this.config.api.root}/authentication/authToken`;
			let params = new HttpParams();
			params = params.set("publicKeyId", publicKeyId);
			return this.http.get<AuthenticateToken>(url, { params });
		}
	};

	public authGovToken = {
		get$: (publicKeyId: string): Observable<AuthenticateToken> => {
			const url = `${this.config.api.gov}/authentication/authToken`;
			let params = new HttpParams();
			params = params.set("publicKeyId", publicKeyId);
			return this.http.get<AuthenticateToken>(url, { params });
		}
	};

	public oauth = {
		post$: (accessToken: string): Observable<AuthenticateResponse> => {
			const url = `${this.config.api.root}/authentication/oauth`;
			const options = {
				headers: new HttpHeaders({
					"Content-Type": "application/x-www-form-urlencoded",
				})
			};
			const body = `accessToken=${accessToken}`
			return this.http.post<AuthenticateResponse>(url, body, options);
		}
	};

	public logout = {
		post$: (): Observable<void> => {
			const url = `${this.config.api.root}/authentication/logout`;
			return this.http.post<void>(url, {}, { withCredentials: true });
		}
	};

	public permissions = {
		list: {
			get$: (): Observable<string[]> => {
				const url = `${this.config.api.root}/authentication/permissions/list`;
				return this.http.get<string[]>(url, { withCredentials: true });
			}
		}
	};

	public roles = {
		list: {
			get$: (): Observable<Role[]> => {
				const url = `${this.config.api.root}/authentication/roles/list`;
				return this.http.get<Role[]>(url, { withCredentials: true });
			}
		}
	};

	public userType = {
		get$: (): Observable<UserType> => {
			const url = `${this.config.api.root}/authentication/userType`;
			return this.http.get<UserType>(url, { withCredentials: true });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

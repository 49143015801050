<section>
	<header>
		<h2>Параметры новой накладной</h2>
		<button (click)="close()" type="button">
			<app-icon>close</app-icon>
		</button>
	</header>
	<div [formGroup]="form" class="main">
		<!-- <div class="field-block">
			<label i18n>Версия</label>
			<app-tab
				[tabs]="ewaybillVersionValues"
				[newStyle]="true"
				formControlName="version"
				[class.tab-disabled]="true"
				[isDisabled]="selectDisabled() || ['3', '4'].includes(form?.get('formType').value.id)">
			</app-tab>
		</div> -->
		<div class="field-block">
			<label i18n>Тип</label>
			<app-select-box
				[data]="ewaybillFormTypeValues"
				[showSearch]="false"
				[valueTransformFn]="transformFn"
				[formControl]="form?.controls['formType']"
				[newStyle]="true"
				[isDisabled]="isActiveField(true)">
			</app-select-box>
		</div>
		<div class="field-block">
			<label i18n>Вид</label>
			<app-tab
				[tabs]="ewaybillDraftTypeValues"
				[newStyle]="true"
				[isDisabled]="!isActive && ewaybillMilkPermission || ['4'].includes(form?.get('formType').value.id)"
				formControlName="draftType">
			</app-tab>
		</div>
		<div class="field-block">
			<span>
				<app-checkbox
					[class.disableCheckbox]="['1', '3', '4'].includes(form?.get('formType').value.id)"
					[setDisabled]="['1', '3', '4'].includes(form?.get('formType').value.id)"
					formControlName="isTest">
				</app-checkbox>
				<label>Тестовая электронная накладная (не имеет юридической силы)</label>
			</span>
		</div>
	</div>
	<footer>
		<button class="save" appSkin="action-save bold" (click)="onCreate()">Создать</button>
		<button class="save" appSkin="simple no-shadow bold bordered" (click)="close()">Отмена</button>
	</footer>
</section>

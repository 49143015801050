import { HttpClient } from "@angular/common/http";
import { ConfigurationService } from "@core/configuration.service";
import { Observable } from "rxjs";
import { SectionList } from "@helper/abstraction/sections";

export class Sections {
	public help = {
		data: {
			get$: (): Observable<SectionList[]> => {
				const url = `${this.config.api.root}/section/help/data/help`;
				return this.http.get<SectionList[]>(url);
			}
		},
		page: {
			content: {
				get$: (slug: string): Observable<string> => {
					const url = `${this.config.api.root}/section/help/page/${slug}`;
					return this.http.get<string>(url, { responseType: "text" as "json" });
				}
			}
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

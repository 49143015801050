import { HttpErrorResponse } from "@angular/common/http";
import { createAction } from "@ngrx/store";
import { AttachSignParams, MobileEdsAuth, SignAParams, SignedSCCryptoDto } from "@helper/abstraction/sccrypto";

export const getTokenForCertificateBinding = createAction("[SCCrypto] Get Token");
export const getTokenForCertificateBindingSuccess = createAction(
	"[SCCrypto]  Get Token Success",
	(payload: number[]): { token: number[] } => ({ token: payload })
);
export const getTokenForCertificateBindingError = createAction(
	"[SCCrypto]  Get Token Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const signA = createAction(
	"[SCCrypto] Sign A",
	(value: SignAParams, token: number[]): { value: SignAParams; token: number[] } => ({ value, token })
);
export const signASuccess = createAction(
	"[SCCrypto] Sign A Success",
	(value: SignedSCCryptoDto, token: number[]): { value: SignedSCCryptoDto; token: number[] } => ({ value, token })
);
export const signAError = createAction(
	"[SCCrypto] Sign A Error",
	(payload: SignedSCCryptoDto): { error: SignedSCCryptoDto } => ({ error: payload })
);

export const bindCertificateToUser = createAction(
	"[SCCrypto] Bind Certificate To User",
	(payload: AttachSignParams): { value: AttachSignParams } => ({ value: payload })
);
export const bindCertificateToUserSuccess = createAction(
	"[SCCrypto] Bind Certificate To User Success",
	(certificateName?: string, certificateSerialNumber?: string): { certificateName?: string; certificateSerialNumber?: string } => ({ certificateName, certificateSerialNumber })
);
export const bindCertificateToUserError = createAction(
	"[SCCrypto] Bind Certificate To User Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const attachCryptoMobile = createAction(
	"[SCCrypto] Attach Crypto Mobile",
	(mobileEds: MobileEdsAuth): { mobileEds: MobileEdsAuth } => ({ mobileEds })
);
export const attachCryptoMobileSuccess = createAction(
	"[SCCrypto] Attach Crypto Mobile Success",
	(mobileEds?: MobileEdsAuth): { mobileEds?: MobileEdsAuth } => ({ mobileEds })
);
export const attachCryptoMobileError = createAction(
	"[SCCrypto] Attach Crypto Mobile Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const authorizeCryptoMobile = createAction("[SCCrypto] Authorize Crypto Mobile");
export const authorizeCryptoMobileSuccess = createAction(
	"[SCCrypto] Authorize Crypto Mobile Success",
	(mobileEds?: MobileEdsAuth): { mobileEds?: MobileEdsAuth } => ({ mobileEds })
);
export const authorizeCryptoMobileError = createAction(
	"[SCCrypto] Authorize Crypto Mobile Error",
	(payload: HttpErrorResponse): { error: HttpErrorResponse } => ({ error: payload })
);

export const detachCryptoMobile = createAction("[SCCrypto] Detach Crypto Mobile");
export const detachCryptoMobileSuccess = createAction(
	"[SCCrypto] Detach Crypto Mobile Success"
);
export const detachCryptoMobileError = createAction(
	"[SCCrypto] Detach Crypto Mobile Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

export const unbindCertificateByUser = createAction("[SCCrypto] Unbind Certificate By User");
export const unbindCertificateBYUserSuccess = createAction(
	"[SCCrypto] Unbind Certificate By User Success"
);
export const unbindCertificateByUserError = createAction(
	"[SCCrypto] Unbind Certificate By User Error",
	(payload: Error): { error: Error } => ({ error: payload })
);

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "@core/configuration.service";

import { DocumentType, DocumentKind } from "@helper/abstraction/documents";
import { Einvoicepmt } from "@app/user/user-core/api/einvoicepmt";
import { Edocument } from "@app/user/user-core/api/edocument";
import { UserPermissionService } from "./user-permission.service";
import { Crypto } from "@app/user/user-core/api/crypto";
import { Store } from "@ngrx/store";
import { Registration } from "@app/user/user-core/api/registration";
import { CounterParty } from "@app/user/user-core/api/counter-party";
import { Activemq } from "../api/activemq";
import { Authentication } from "../api/authenticate";
import { Datamark } from "../api/datamark";
import { Desadv } from "../api/desadv";
import { DocumentController } from "../api/document-controller";
import { Documents } from "../api/documents";
import { Draft } from "../api/draft";
import { Einvoice } from "../api/einvoice";
import { Epass } from "../api/epass";
import { Ewaybill } from "../api/ewaybill";
import { Export } from "../api/export";
import { FormatDocument } from "../api/format-document";
import { Import } from "../api/import";
import { Mns } from "../api/mns";
import { NotificationController } from "../api/notification-controller";
import { Nsi } from "../api/nsi";
import { Order } from "../api/orders";
import { Organization } from "../api/organization";
import { Provider } from "../api/provider";
import { Sections } from "../api/sections";
import { Signing } from "../api/signing";
import { Statistic } from "../api/statistic";
import { Storages } from "../api/storages";
import { Transit } from "../api/transit";
import { User } from "../api/user";
import { Config } from "../api/config";
import { Eact } from "../api/eact";
import { Directions } from "../api/directions";
import { Ecmr } from "../api/ecmr";
import { Banner } from "../api/banner";
import { Roseu } from "../api/roseu";

@Injectable()
export class UserBackendService {
	public isInaction = false;

	public readonly activemq: Activemq;
	public readonly authenticate: Authentication;
	public readonly desadv: Desadv;
	public readonly documents: Documents;
	public readonly draft: Draft;
	public readonly provider: Provider;
	public readonly einvoice: Einvoice;
	public readonly edocument: Edocument;
	public readonly ewaybill: Ewaybill;
	public readonly export: Export;
	public readonly formatDocument: FormatDocument;
	public readonly nsi: Nsi;
	public readonly order: Order;
	public readonly organization: Organization;
	public readonly sections: Sections;
	public readonly signing: Signing;
	public readonly statistic: Statistic;
	public readonly storages: Storages;
	public readonly user: User;
	public readonly einvoicepmt: Einvoicepmt;
	public readonly transit: Transit;
	public readonly crypto: Crypto;
	public readonly config: Config;
	public readonly import: Import;
	public readonly mns: Mns;
	public readonly registration: Registration;
	public readonly counterParty: CounterParty;
	public readonly datamark: Datamark;
	public readonly epass: Epass;
	public readonly notificationController: NotificationController;
	public readonly eact: Eact;
	public readonly ecmr: Ecmr;
	public readonly roseu: Roseu;
	public readonly directions: Directions;
	public readonly banner: Banner;

	constructor(
		store: Store,
		configurationService: ConfigurationService,
		httpClient: HttpClient,
		userPermissionService: UserPermissionService
	) {
		this.authenticate = new Authentication(configurationService, httpClient);
		this.organization = new Organization(configurationService, httpClient);
		this.storages = new Storages(configurationService, httpClient);
		this.sections = new Sections(configurationService, httpClient);
		this.nsi = new Nsi(configurationService, httpClient);
		this.draft = new Draft(configurationService, httpClient);
		this.provider = new Provider(configurationService, httpClient);
		this.signing = new Signing(configurationService, httpClient);
		this.user = new User(configurationService, httpClient);
		this.formatDocument = new FormatDocument(configurationService, httpClient);
		this.statistic = new Statistic(configurationService, httpClient);
		this.activemq = new Activemq(configurationService, httpClient);
		this.export = new Export(configurationService, httpClient);
		this.ewaybill = new Ewaybill(configurationService, httpClient);
		this.einvoice = new Einvoice(configurationService, httpClient);
		this.edocument = new Edocument(configurationService, httpClient);
		this.desadv = new Desadv(configurationService, httpClient);
		this.order = new Order(configurationService, httpClient);
		this.documents = new Documents(store, configurationService, httpClient, this, userPermissionService);
		this.einvoicepmt = new Einvoicepmt(configurationService, httpClient);
		this.transit = new Transit(configurationService, httpClient);
		this.config = new Config(configurationService, httpClient);
		this.import = new Import(configurationService, httpClient);
		this.mns = new Mns(store, configurationService, httpClient);
		this.crypto = new Crypto(configurationService, httpClient);
		this.registration = new Registration(configurationService, httpClient);
		this.counterParty = new CounterParty(configurationService, httpClient);
		this.datamark = new Datamark(configurationService, httpClient);
		this.epass = new Epass(configurationService, httpClient);
		this.notificationController = new NotificationController(configurationService, httpClient);
		this.eact = new Eact(configurationService, httpClient);
		this.ecmr = new Ecmr(configurationService, httpClient);
		this.directions = new Directions(configurationService, httpClient);
		this.banner = new Banner(configurationService, httpClient);
		this.roseu = new Roseu(configurationService, httpClient);
	}

	public getController(type: DocumentType): DocumentController<DocumentKind> {
		switch (type) {
			case "DESADV":
				return this.desadv;
			case "EWAYBILL":
				return this.ewaybill;
			case "EINVOICE":
				return this.einvoice;
			case "ORDERS":
				return this.order;
			case "EINVOICEPMT":
				return this.einvoicepmt;
			case "TRANSIT":
				return this.transit;
			case "EDOCUMENT":
				return this.edocument;
			case "EACTDIF":
				return this.eact;
			case "ECMR":
				return this.ecmr;
			case "ROSEU":
				return this.roseu;
			default:
				throw Error(`Controller for ${type} doesn't exist`);
		}
	}
}

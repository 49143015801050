import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Component, ChangeDetectionStrategy, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { DefaultPopupComponent } from "@shared/overlay/default-pop-up.component";
import { CreateDocumentParams } from "@helper/abstraction/documents";
import { Status } from "@helper/abstraction/status";

export type FormType = Array<[{id: string, name: string}, string, string[]?]>;

export const EwaybillFormTypeValues: FormType = [
	[{ id: "0", name: "Типовая" }, "Типовая"],
	[{ id: "1", name: "Факторинг" }, "Факторинг", ["CTT"]],
	[{ id: "2", name: "Молоко" }, "Молоко", ["CTT"]],
	[{ id: "3", name: "Экспортная" }, "Экспортная"],
	[{ id: "4", name: "Иной документ" }, "Иной документ"],
];

const EwaybillVersionValues = [
	["После 01.05.2024", "1"],
	["До 01.05.2024", "0"],
];

const EwaybillDraftTypeValues = [
	["ТТН", "BLRWBL"],
	["ТН", "BLRDLN"],
];

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-ewaybill-create-popup",
	styleUrls: ["./ewaybill-create-popup.component.scss"],
	templateUrl: "./ewaybill-create-popup.component.html",
})

export class EwaybillCreatePopupComponent extends DefaultPopupComponent implements OnInit {
	@Input() public route?: ActivatedRoute;
	@Input() public ewaybillMilkPermission = false;
	@Input() public ewaybillFactoringPermission = false;
	@Input() public ewaybillOtherDocumentPermission = false;
	@Input() public isCreateFromOrderOrShipmentNotification = false;
	@Input() public documentVersion?: number;
	@Input() public formTypeDocument?: number;
	public ewaybillFormTypeValues = EwaybillFormTypeValues;
	public ewaybillDraftTypeValues = EwaybillDraftTypeValues;
	public ewaybillVersionValues = EwaybillVersionValues;
	public form?: FormGroup;
	public isActive = false;
	public viewItemList: boolean[] = [true, false, false, true];
	private unsubscribe$$: Subject<void> = new Subject<void>();

	constructor(
		private fb: FormBuilder,
		private router: Router
	) {
		super();
	}

	public ngOnInit(): void {
		this.viewItemList = [true, this.ewaybillFactoringPermission, this.ewaybillMilkPermission, true, this.ewaybillOtherDocumentPermission];
		this.handleFormTypeValues();
		this.initForm();
		this.form?.get("formType")?.valueChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(formType => {
			this.isActive = formType.id !== "2";
			this.form?.get("draftType")?.patchValue("BLRWBL");
			this.form?.get("isTest")?.patchValue(formType.id === "1", { emitEvent: true });
			/** TODO disable milk version */
			// this.form?.get("version")?.patchValue(formType.id === "2" ? "0" : "1", { emitEvent: true });
			if (formType.id === "4") {
				this.form?.get("draftType")?.patchValue("BLRDLN");
			}
		});
		this.form?.get("version")?.valueChanges.pipe(takeUntil(this.unsubscribe$$)).subscribe(ver => {
			this.handleFormTypeValues(ver === "0");
		});
	}

	public onCreate(): void {
		const params: CreateDocumentParams = this.form?.getRawValue();
		if (this.form?.valid) {
			const queryParams = params.isTest ? {
				draftType: params.draftType,
				formType: params.formType?.id,
				version: params.version,
				isTest: 1
			} : {
				draftType: params.draftType,
				formType: params.formType?.id,
				version: params.version,
			};
			this.router.navigateByUrl(
				this.router.createUrlTree(["/user/documents/EWAYBILL/create"], { queryParams })
			).then(() => this.close());
		}
	}

	public isActiveField(isFormType = false): boolean {
		return typeof (isFormType && this.formTypeDocument ? this.formTypeDocument : this.documentVersion) === "number";
	}

	public selectDisabled(): boolean {
		return this.isCreateFromOrderOrShipmentNotification ? false : this.isActiveField();
	}

	public transformFn(status: Status): string {
		return status.name;
	}

	private initForm(): void {
		this.form = this.fb.group({
			draftType: [this.formTypeDocument === 4 ? "BLRDLN" : "BLRWBL", Validators.required],
			formType: [this.handleFormType()],
			isTest: [this.formTypeDocument === 1 || false],
			version: [this.isActiveField() ? `${this.documentVersion}` : "1"]
		});
		this.isActive = this.formTypeDocument !== 2 && this.ewaybillMilkPermission;
	}

	private handleFormType(): { id: string, name: string } {
		return this.ewaybillFormTypeValues.find(el => el[0].id === String(this.formTypeDocument ?? 0))![0];
	}

	private handleFormTypeValues(filter = false): void {
		const eFTV = EwaybillFormTypeValues;
		let defaultValues = !filter ? eFTV : this.ewaybillFormTypeValues.filter((el: any) => !["3", "4"].includes(el[0].id));
		// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
		this.ewaybillFormTypeValues = defaultValues.filter((el: any, i: number) => {
			if (this.viewItemList[i]) return el;
		});
	}
}

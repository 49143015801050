import { NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { UserAuthGuard } from "./guardians/user-auth.guard";
import { UserAuthInterceptor } from "./interceptors/user-auth.interceptor";
import { UserAuthService } from "./services/user-auth.service";
import { BlobHttpErrorResponseInterceptor } from "./interceptors/blob-http-error-response.interceptor";
import { SseService } from "./server-sent-events.service";
import { MnsAuthGuard } from "../../mns/mns-core/mns-auth.guard";
import { HttpDocumentNotFoundInterceptor } from "./interceptors/http-document-not-found.interceptor";
import { UserLogoutInterceptor } from "./interceptors/user-logout.interceptor";
import { UserTypeGuard } from "./guardians/user-type.guard";
import { UserLogoutGuard } from "./guardians/user-logout.guard";
import { UserErrorInterceptor } from "./interceptors/user-error.interceptor";
import { UserBackendService } from "./services/user-backend.service";
import { UserCookieService } from "./services/user-cookie.service";
import { UserErrorsService } from "./services/user-errors.service";
import { UserFilterService } from "./services/user-filter.service";
import { UserOpenDocumentFileService } from "./services/user-open-document-file.service";
import { UserPermissionService } from "./services/user-permission.service";
import { UserRoutingService } from "./services/user-routing.service";
import { UserTypeService } from "./services/user-type.service";
import { CheckDocumentsService } from "./services/check-documents.service";
import { AdminGuard } from "./guardians/admin.guard";
import { InactionInterceptor } from "@core/inaction.interceptor";

@NgModule({
	imports: [
		HttpClientModule,
	],
	providers: [
		UserAuthService,
		UserErrorsService,
		UserAuthGuard,
		UserLogoutGuard,
		UserBackendService,
		UserPermissionService,
		UserFilterService,
		UserRoutingService,
		UserTypeGuard,
		AdminGuard,
		MnsAuthGuard,
		UserTypeService,
		UserCookieService,
		UserOpenDocumentFileService,
		CheckDocumentsService,
		SseService,
		{ provide: HTTP_INTERCEPTORS, useClass: InactionInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: UserAuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: UserLogoutInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpDocumentNotFoundInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: UserErrorInterceptor, multi: true },
		{ provide: "itemsToStore", useValue: 5 },
		{ provide: HTTP_INTERCEPTORS, useClass: BlobHttpErrorResponseInterceptor, multi: true }
	],
	declarations: []
})
export class UserCoreModule { }

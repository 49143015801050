import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import * as runtime from "./runtime/constants";
import { environment } from "./environments/environment";

// eslint-disable-next-line no-undef
if (environment.production)
	enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);

if (!(window as any).appRuntime) {
	(window as any).appRuntime = runtime;
}

import { HttpErrorResponse } from "@angular/common/http";
import { StorageCreate, StorageCreateDto } from "@helper/abstraction/storages";
import { createAction } from "@ngrx/store";
import { FindInfoForCreatingCode } from "./storage";

export const findInfo = createAction(
	"[Storage] Find Info",
	(gln?: string): { gln?: string } => ({ gln })
);
export const findInfoSuccess = createAction(
	"[Storage] Find Info Success",
	(storage: FindInfoForCreatingCode): { storage: FindInfoForCreatingCode } => ({ storage })
);
export const findInfoError = createAction(
	"[Storage] Find Info Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

export const createPartyStorage = createAction(
	"[Storage] Create Party Storage",
	(storage: StorageCreate): { storage: StorageCreate } => ({ storage })
);
export const createPartyStorageSuccess = createAction("[Storage] Create Party Storage Success");
export const createPartyStorageError = createAction(
	"[Storage] Create Party Storage Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

export const editPartyStorage = createAction(
	"[Storage] Edit Party Storage",
	(storage: StorageCreate): { storage: StorageCreate } => ({ storage })
);
export const editPartyStorageSuccess = createAction("[Storage] Edit Party Storage Success");
export const editPartyStorageError = createAction(
	"[Storage] Edit Party Storage Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

export const createStorage = createAction(
	"[Storage] Create Storage",
	(partyId: number, storageGln: string): { partyId: number; storageGln: string } => ({ partyId, storageGln })
);
export const createStorageSuccess = createAction(
	"[Storage] Create Storage Success",
	(storage: StorageCreateDto): { storage: StorageCreateDto } => ({ storage })
);
export const createStorageError = createAction(
	"[Storage] Create Storage Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

export const actualizeAddressStorage = createAction(
	"[Storage] Actualize Address Storage",
	(storageGln: string): { storageGln: string } => ({ storageGln })
);
export const actualizeAddressStorageSuccess = createAction(
	"[Storage] Actualize Address Storage Success",
	(addressFull: string): { addressFull: string } => ({ addressFull })
);
export const actualizeAddressStorageError = createAction(
	"[Storage] Actualize Address Storage Error",
	(error: HttpErrorResponse): { error: HttpErrorResponse } => ({ error })
);

import { ConfigurationService } from "@core/configuration.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs";
import { DraftDto } from "@helper/abstraction/draft";

export class Transit extends DocumentController<any> {
	public get apiUrl(): string {
		return `${this.config.api.transit}`;
	}

	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	public checkSign = {
		get$: (documentId: string): Observable<boolean> => {
			const url = `${this.apiUrl}/${documentId}/checkSign`;
			return this.http.get<boolean>(url, { withCredentials: true });
		}
	};

	public cancel = {
		get$: (documentId: string, isVersion = false, base64?: boolean): Observable<DraftDto> => {
			const url = `${this.handleApi(isVersion)}/${documentId}/cancel`;
			let params = new HttpParams();
			if (typeof base64 === "boolean") {
				params = params.set("base64", base64!.toString());
			}
			return this.http.get<DraftDto>(url, { withCredentials: true, params });
		}
	};

	private handleApi(isVersion: boolean): string {
		return this.config.handleVersionPath(this.config.api.transit, isVersion ? undefined : "");
	}

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { DirectionFilter, Partner, ThirdPartyDirectionDto, ThirdPartyDirectionHistoryDto } from "@app/user/customization/directions/directions.model";
import { ConfigurationService } from "@core/configuration.service";
import { DirectionsPage } from "../../customization/directions/directions.model";

export class Directions {
	public clientsList = {
		get$: (): Observable<any> => {
			const url = `${this.config.api.root}/directions/clientsList`;
			return this.http.get<any>(url, { withCredentials: true });
		}
	};

	public directionsList = {
		post$: (filterData: DirectionFilter): Observable<DirectionsPage> => {
			const url = `${this.config.api.root}/directions/filteredPage/${filterData.partnerId}`;
			const filter = {
				// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
				name: filterData.filter?.name!,
				statusList: filterData.filter?.statusList,
			};

			let params = new HttpParams();
			// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
			params = params.set("page", filterData?.filter?.page!?.toString());
			// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
			params = params.set("size", filterData?.filter?.size!?.toString());
			return this.http.post<DirectionsPage>(url, filter, { withCredentials: true, params });
		}
	};

	public findDirection = {
		get$: (directionId: number): Observable<ThirdPartyDirectionDto> => {
			const url = `${this.config.api.root}/directions/find/${directionId}`;
			return this.http.get<ThirdPartyDirectionDto>(url, { withCredentials: true });
		}
	};

	public partnersList = {
		get$: (id: number): Observable<Partner[]> => {
			const url = `${this.config.api.root}/organization/partners/${id}/list`;
			return this.http.get<Partner[]>(url, { withCredentials: true });
		}
	};

	public createDirection = {
		post$: (directionDto: ThirdPartyDirectionDto): Observable<number> => {
			const url = `${this.config.api.root}/directions/create`;
			return this.http.post<number>(url, directionDto, { withCredentials: true });
		}
	};

	public changeDirection = {
		post$: (directionDto: ThirdPartyDirectionDto): Observable<void> => {
			const url = `${this.config.api.root}/directions/change/${directionDto.id}`;
			return this.http.post<void>(url, directionDto, { withCredentials: true });
		}
	};

	public suspendDirection = {
		post$: (directionId: number): Observable<any> => {
			const url = `${this.config.api.root}/directions/suspend/${directionId}`;
			return this.http.post<any>(url, { withCredentials: true });
		}
	};

	public getHistory = {
		get$: (groupId: number): Observable<ThirdPartyDirectionHistoryDto[]> => {
			const url = `${this.config.api.root}/directions/history/${groupId}`;
			return this.http.get<ThirdPartyDirectionHistoryDto[]>(url, { withCredentials: true });
		}
	};

	constructor(private config: ConfigurationService, private http: HttpClient) { }
}

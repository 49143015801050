import { ConfigurationService } from "@core/configuration.service";
import { HttpClient } from "@angular/common/http";
import { DocumentController } from "./document-controller";
import { Observable } from "rxjs";
// import { DraftDto } from "@helper/abstraction/draft";

export class Ecmr extends DocumentController<any> {
	public get apiUrl(): string { return `${this.config.api.ecmr}`; }

	public uniqueNumber = {
		get$: (): Observable<{ uniqueNumber: string }> => {
			const url = `${this.apiUrl}/uniqueNumber`;
			return this.http.get<{ uniqueNumber: string }>(url, { withCredentials: true });
		}
	};

	constructor(
		private config: ConfigurationService,
		http: HttpClient
	) {
		super(http);
	}
}
